import React, {useContext} from "react";
import {Col, Container, Row} from "reactstrap";
import {ArgumentView} from "../panels/ArgumentView";
import {GlobalStore} from "../../stores/GlobalStore";
import {StoryView} from "../panels/StoryView";
import {NonExistingStoryPanel} from "../panels/NonExistingStoryPanel";
import {observer} from "mobx-react-lite";
import {NonExistingArgumentPanel} from "../panels/NonExistingArgumentPanel";

export const ArgumentStudyView = observer(({argumentsStudyStore}) => {
    const {stories, argumentsStore} = useContext(GlobalStore);
    let target = null;
    if (argumentsStudyStore.argument.targetStory) {
        const targetStory = stories.stories.get(argumentsStudyStore.argument.targetStory);
        if (targetStory){
            target = (
                <StoryView story={targetStory} onClick={() => argumentsStudyStore.toggleStory(targetStory)}/>
            )
        } else {
            target =(
                <NonExistingStoryPanel storyId={argumentsStudyStore.argument.targetStory}/>
            )
        }
    } else {
        const targetArgument = argumentsStore.argumentsMap.get(argumentsStudyStore.argument.targetArgument);
        if (targetArgument) {
            target = (
                <ArgumentView argument={targetArgument}
                              onClick={() => argumentsStudyStore.toggleArg(targetArgument)}/>
            );
        } else {
            target =(
                <NonExistingArgumentPanel argumentId={argumentsStudyStore.argument.targetArgument} />
            )
        }
    }
    const objections = argumentsStore.allArguments.filter(arg => arg.targetArgument === argumentsStudyStore.argument.id).map((arg, index) => (
        <ArgumentView argument={arg} key={arg.id} onClick={() => argumentsStudyStore.toggleArg(arg)}/>
    ));
    const reasoningStory = stories.stories.get(argumentsStudyStore.argument.reasoning);
    return (
        <Container>
            <Row>
                <Col tag="aside" id="target" sm={3}>
                    <h3>Target</h3>
                    {target}
                </Col>
                <Col tag="main" sm={6}>
                    <h1>Argument</h1>
                    <ArgumentView argument={argumentsStudyStore.argument}
                                  onClick={() => argumentsStudyStore.toggleArg(argumentsStudyStore.argument)}/>
                    {objections.length > 0 ? <h3>Objections</h3> : ""}
                    {objections}
                </Col>
                <Col tag="aside" id="reasoning" sm={3}>
                    <h3>Reasoning</h3>
                    <StoryView story={reasoningStory}
                               onClick={() => argumentsStudyStore.toggleStory(reasoningStory)}/>
                </Col>
            </Row>
        </Container>
    );
});

