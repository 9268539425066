import {action, computed, decorate, observable} from "mobx";
import {default as DraftPublic, EditorState} from "draft-js";
import {EditorUtils} from "../utils/EditorUtils";

export class Fragment {
    story;
    editorState = EditorState.createEmpty();
    currentSelection;
    expand = true;

    constructor(story) {
        const parsed = JSON.parse(story.content);
        const newContentState = DraftPublic.convertFromRaw(parsed);
        this.editorState = EditorState.createWithContent(newContentState);
        this.story = story;
        this.currentSelection = this.editorState.getSelection();
    }

    get startIndex() {
        const startKey = this.currentSelection.getStartKey();
        return EditorUtils.indexOf(this.editorState, startKey);
    }

    get endIndex() {
        const endKey = this.currentSelection.getEndKey();
        return EditorUtils.indexOf(this.editorState, endKey);
    }

    collapse() {
        this.expand = false;
    }

    setState(newState) {
        this.currentSelection = newState.getSelection();

        if (!this.editorState) {
            this.editorState = newState;
            return;
        }
        const currentContentState = this.editorState.getCurrentContent();
        const newContentState = newState.getCurrentContent();

        if (currentContentState !== newContentState) {
            console.log("The state should have been read only...");
        } else {
            this.editorState = newState;
        }
    }

    setSelectionState(selection) {
        this.editorState = EditorUtils.applySelection(this.editorState, selection);
        this.currentSelection = this.editorState.getSelection();
        this.expand = false;
    }

}

export class AutoCompleteStore {
    filter = "";
    showSuggestions = false;
    storyId;
    stories;
    goodTags = [];
    badTags = [];
    problem;

    constructor(stories, goodTags = [], badTags = [], onSelection) {
        this.stories = stories;
        this.goodTags = goodTags;
        this.badTags = badTags;
        this.onSelection = onSelection;
    }

    get filtered() {
        let stories = Array.from(this.stories.values());
        if (this.goodTags.length > 0) {
            stories = stories.filter(story => this.intersection(story.tags, this.goodTags).length > 0)
        }
        if (this.badTags.length > 0) {
            stories = stories.filter(story => this.intersection(story.tags, this.badTags).length === 0)
        }
        return stories.filter((story) => (story.title.toLowerCase().includes(this.filter.toLocaleLowerCase()))).splice(0, 5);

    }

    get story() {
        return this.stories.get(this.storyId);
    }

    setFilter(filter) {
        this.filter = filter;
        this.storyId = null;
    }

    selectStory(storyId) {
        this.storyId = storyId;
        const selectedStory = this.stories.get(storyId);
        if (selectedStory) {
            this.filter = selectedStory.title;
        }
        this.showSuggestions = false;
        if (this.onSelection) {
            this.onSelection();
        }
        if (this.onSelection) {
            this.onSelection();
        }
    }

    setShowSuggestions(show) {
        this.showSuggestions = show;
    }

    intersection(tags, otherTags) {
        return tags.filter(value => otherTags.includes(value));
    }

    fragmentForSelection(selection) {
        const selectedStory = this.stories.get(this.storyId);
        if (!selectedStory) {
            return null;
        }
        const fragment = new Fragment(selectedStory);
        if (selection) {
            fragment.setSelectionState({
                startIndex: selection.startIndex,
                startOffset: selection.startOffset,
                endIndex: selection.endIndex,
                endOffset: selection.endOffset
            });
        }
        return fragment;
    }

    setProblem(problem) {
        this.problem = problem;
    }

}


decorate(Fragment, {
    story: observable,
    editorState: observable,
    currentSelection: observable,
    expand: observable,

    collapse: action,
    setSelectionState: action,
    setState: action
});

decorate(AutoCompleteStore, {
    filter: observable,
    showSuggestions: observable,
    storyId: observable,
    problem: observable,

    setFilter: action,
    selectStory: action,
    setShowSuggestions: action,
    setProblem: action,

    filtered: computed,
    story: computed
});