import React, {useContext} from "react";
import {Alert} from "reactstrap";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const NewStoryAlert = ({story, toggle}) => {
    const {exploreFilterStore} = useContext(GlobalStore);
    return (
        <Alert color="success" isOpen={true} toggle={toggle}>
            Story <Link to="/explore/stories" onClick={() => {
            exploreFilterStore.toggleStory(story)
        }}>{story.id}</Link> was composed.
        </Alert>
    )
};
