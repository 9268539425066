import {action, computed, decorate, observable} from "mobx";

import JSZip from "jszip";
import saveAs from "jszip/vendor/FileSaver"
import tutorialStories from "../tutorial/tutorialStories"
import tutorialArguments from "../tutorial/tutorialArguments"
import {CritiquesStore} from "./CritiquesStore";
import {StoriesStore} from "./StoriesStore";
import {ArgumentsStore} from "./ArgumentsStore";

export class MemoryStore {
    autoLoad = false;
    autoSave = false;
    preventTutorial = false;
    textToImport = "";
    errorFeedback;
    importMessage;
    parsedInput;
    pasteBinUrl = "";
    gistGithubUrl = "";


    constructor(storiesStore, argumentsStore, critiquesStore, alertsStore) {
        this.storiesStore = storiesStore;
        this.argumentsStore = argumentsStore;
        this.critiquesStore = critiquesStore;
        this.alertsStore = alertsStore;
    }

    init(){
        const load = localStorage.getItem("autoLoad");
        if (load === "true") {
            this.loadMemory();
            this.autoLoad = true;
        } else {
            this.autoLoad = false;
        }
        const save = localStorage.getItem("autoSave");
        if (save === "true") {
            this.autoSave = true;
            this.alertsStore.subscribe(this.onMessage.bind(this));
        } else {
            this.autoSave = false;
        }
        const tutorial = localStorage.getItem("preventTutorial");
        if (tutorial === "true") {
            this.preventTutorial = true;
        } else {
            this.preventTutorial = false;
            this.loadTutorial();
        }
    }

    get numberOfStories() {
        return this.storiesStore.stories.size;
    }

    get numberOfArguments() {
        return this.argumentsStore.argumentsMap.size;
    }
    get numberOfCritiques() {
        return this.critiquesStore.critiquesMap.size;
    }

    get importMessage() {
        const data = this.parsedInput;
        if (!data) {
            return null;
        }
        const stories = this.validateEntities(data.stories, StoriesStore.validateStory);
        const args = this.validateEntities(data.args, ArgumentsStore.validateArg);
        const critiques = this.validateEntities(data.critiques, CritiquesStore.validateCritique);

        return "Found " + stories + " stories, " + args + " arguments, " + critiques + " critiques";
    }

    get gistUrlContainsRaw() {
        return this.gistGithubUrl.includes("/raw/")
    }

    validateEntities(stories, validationMethod) {
        let count = 0;
        try {
            stories.forEach(story => {
                if (validationMethod(story)) {
                    count++;
                }
            })
        } catch (error) {
            console.error(error);
        }
        return count;
    }

    setAutoLoad(checked) {
        if (checked == null) {
            this.autoLoad = !this.autoLoad;
        } else {
            this.autoLoad = !!checked;
        }
        if (this.autoLoad) {
            localStorage.setItem("autoLoad", "true");
        } else {
            localStorage.removeItem("autoLoad");
        }
    }

    setAutoSave(checked) {
        if (checked == null) {
            this.autoSave = !this.autoSave;
        } else {
            this.autoSave = !!checked;
        }
        if (this.autoSave) {
            localStorage.setItem("autoSave", "true");
            this.alertsStore.subscribe(this.onMessage.bind(this));
        } else {
            localStorage.removeItem("autoSave");
        }
    }

    setPreventTutorial(checked) {
        if (checked == null) {
            this.preventTutorial = !this.preventTutorial;
        } else {
            this.preventTutorial = !!checked;
        }
        if (this.preventTutorial) {
            localStorage.setItem("preventTutorial", "true");
        } else {
            localStorage.removeItem("preventTutorial");
        }
    }

    loadMemory() {
        const storiesJSON = localStorage.getItem("stories");
        let count = this.loadStoriesFromJSON(storiesJSON);
        console.log("Loaded " + count + " stories from local storage.");

        const argumentsJSON = localStorage.getItem("arguments");
        count = this.loadArgumentsFromJSON(argumentsJSON);
        console.log("Loaded " + count + " arguments from local storage.");

        const critiqueJSON = localStorage.getItem("critiques");
        count = this.loadCritiquesFromJSON(critiqueJSON);
        console.log("Loaded " + count + " critiques from local storage.");
    }

    loadCritiques(critiques) {
        let count = 0;
        if (critiques) {
            critiques.forEach(item => {
                count++;
                this.critiquesStore.createCritique(item);
            });
        }
        return count;
    }

    loadCritiquesFromJSON(critiqueJSON) {
        if (critiqueJSON != null) {
            return this.loadCritiques(JSON.parse(critiqueJSON));
        }
        return 0;
    }

    loadArguments(args) {
        let count = 0;
        if (args) {
            args.forEach(item => {
                count++;
                this.argumentsStore.createArgument(item);
            });
        }
        return count;
    }

    loadArgumentsFromJSON(argumentsJSON) {
        let count = 0;
        if (argumentsJSON != null) {
            return this.loadArguments(JSON.parse(argumentsJSON));
        }
        return count;
    }

    loadStories(stories) {
        let count = 0;
        if (stories) {
            stories.forEach(item => {
                count++;
                this.storiesStore.createStory(item);
            });
        }
        return count;
    }

    loadStoriesFromJSON(storiesJSON) {
        let count = 0;
        if (storiesJSON != null) {
            return this.loadStories(JSON.parse(storiesJSON));
        }
        return count;
    }

    loadTutorial() {
        if (tutorialStories) {
            let count = this.loadStories(tutorialStories);
            console.log("Loaded " + count + " tutorial stories.");
        }
        if (tutorialArguments) {
            let count = this.loadArguments(tutorialArguments);
            console.log("Loaded " + count + " tutorial arguments.");
        }
    }

    saveMemory() {
        const stories = this.storiesStore.allStories;
        localStorage.setItem("stories", JSON.stringify(stories.map(s=>s.valueObject)));
        console.log("Saved " + stories.length + " stories into local storage.");

        const args = this.argumentsStore.allArguments;
        localStorage.setItem("arguments", JSON.stringify(args));
        console.log("Saved " + args.length + " arguments into local storage.");

        const critiques = this.critiquesStore.allCritiques;
        localStorage.setItem("critiques", JSON.stringify(critiques));
        console.log("Saved " + critiques.length + " critiques into local storage.");

    }

    downloadZip() {
        let zip = new JSZip();
        const stories = this.storiesStore.allStories;
        const args = this.argumentsStore.allArguments;
        const critiques = this.critiquesStore.allCritiques;
        zip.file("memory.json", JSON.stringify({
            stories: stories,
            args: args,
            critiques: critiques
        }));
        zip.generateAsync({
            type: "blob", compression: "DEFLATE",
            compressionOptions: {
                level: 9
            }
        })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "memory.zip");
            });
    }

    importData() {

        const data = this.parsedInput;
        if (!data) {
            return;
        }

        let count = this.loadStories(data.stories);
        console.log("Imported " + count + " stories.");
        count = this.loadArguments(data.args);
        console.log("Imported " + count + " arguments.");
        count = this.loadCritiques(data.critiques);
        console.log("Imported " + count + " critiques.");

    }

    setTextToImport(json) {
        this.textToImport = json;
        try {
            this.errorFeedback = null;
            this.parsedInput = JSON.parse(this.textToImport);
        } catch (error) {
            this.errorFeedback = "Cannot parse the input, did you paste it properly?";
            this.parsedInput = null;
        }
    }

    copyToClipBoard(critiqueToCopy) {


        const data = this.collectData(critiqueToCopy);

        let textField = document.createElement('textarea');
        textField.innerText = JSON.stringify(data);
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    collectData(critiqueToCopy) {
        let stories = [];
        if (critiqueToCopy.story) {
            stories.push(this.storiesStore.stories.get(critiqueToCopy.story));
        }
        let argsToProcess = [...critiqueToCopy.args];
        let args = [];

        while (argsToProcess.length > 0) {
            let argToProcess = argsToProcess.shift();
            const arg = this.argumentsStore.argumentsMap.get(argToProcess);
            if (arg) {
                args.push(arg);
                if (arg.targetStory) {
                    stories.push(this.storiesStore.stories.get(arg.targetStory));
                }
                if (arg.targetArgument) {
                    argsToProcess.push(arg.targetArgument);
                }
                if (arg.definition) {
                    stories.push(this.storiesStore.stories.get(arg.definition));
                }
                if (arg.reasoning) {
                    stories.push(this.storiesStore.stories.get(arg.reasoning));
                }
            }
        }
        return {
            stories: stories,
            args: args,
            critiques: [critiqueToCopy]
        };
    }

    setPasteBinUrl(pasteBinUrl) {
        const pastebin = "https://pastebin.com/";
        if (pasteBinUrl.startsWith(pastebin)) {
            pasteBinUrl = pasteBinUrl.substring(pastebin.length, pasteBinUrl.length);
            if (pasteBinUrl.startsWith("raw/")) {
                pasteBinUrl = pasteBinUrl.substring(4, pasteBinUrl.length);
            }
        }
        this.pasteBinUrl = pasteBinUrl;
    }

    setGistGithubUrl(gistGithubUrl) {
        const gistURL = "https://gist.githubusercontent.com/";
        if (gistGithubUrl.startsWith(gistURL)) {
            gistGithubUrl = gistGithubUrl.substring(gistURL.length, gistGithubUrl.length)
        }
        this.gistGithubUrl = gistGithubUrl;
    }

    loadPastebin() {
        let xhr = new XMLHttpRequest();
        xhr.addEventListener("load", data => {
            this.setTextToImport(xhr.response);
            if (!this.errorFeedback) {
                this.importData();
                this.alertsStore.addSuccess("Data imported...")
            }
        });
        xhr.addEventListener("error", err => {
            console.log(err);
            this.alertsStore.addError("There was a problem reading your paste");
        });
        xhr.addEventListener("abort", err => {
            console.log(err);
        });
        xhr.open('GET', "https://pastebin.com/raw/" + this.pasteBinUrl, true);
        xhr.send();
    }

    loadGistGithub() {
        let xhr = new XMLHttpRequest();
        xhr.addEventListener("load", data => {
            this.setTextToImport(xhr.response);
            if (!this.errorFeedback) {
                this.importData();
                this.alertsStore.addSuccess("Data imported...")
            }
        });
        xhr.addEventListener("error", err => {
            console.log(err);
            this.alertsStore.addError("There was a problem reading your gist");
        });
        xhr.addEventListener("abort", err => {
            console.log(err);
        });
        xhr.open('GET', "https://gist.githubusercontent.com/" + this.gistGithubUrl, true);
        xhr.send();
    }

    onMessage(message) {
        if (this.autoSave) {
            this.saveMemory();
        }
    }

    clearStories(){
        this.storiesStore.clear();
    }

    clearArguments(){
        this.argumentsStore.clear();
    }

    clearCritiques(){
        this.critiquesStore.clear();
    }

    clearAll(){
        this.storiesStore.clear();
        this.argumentsStore.clear();
        this.critiquesStore.clear();
    }

}


decorate(MemoryStore, {
    autoLoad: observable,
    autoSave: observable,
    preventTutorial: observable,
    textToImport: observable,
    errorFeedback: observable,
    parsedInput: observable,
    pasteBinUrl: observable,
    gistGithubUrl: observable,

    setAutoLoad: action,
    setAutoSave: action,
    setPreventTutorial: action,
    loadMemory: action,
    saveMemory: action,
    downloadZip: action,
    setTextToImport: action,
    importData: action,
    parseInput: action,
    setPasteBinUrl: action,
    setGistGithubUrl: action,
    clearStories: action,
    clearArguments: action,
    clearCritiques: action,
    clearAll:action,

    numberOfStories: computed,
    numberOfArguments: computed,
    numberOfCritiques: computed,
    importMessage: computed,
    gistUrlContainsRaw: computed


});