import {action, computed, decorate, observable} from "mobx";


export class MentionsStore {

    searchValue;

    constructor(mentions) {
        this.mentions = mentions;
        this.setSearchValue("");
    }

    get suggestions() {
        return this.defaultSuggestionsFilter(this.searchValue, this.mentions);
    }

    defaultSuggestionsFilter(searchValue, suggestions) {
        var value = searchValue.toLowerCase();
        var filteredSuggestions = suggestions.filter(function (suggestion) {
            return !value || suggestion.name.toLowerCase().indexOf(value) > -1;
        });
        var length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
        return filteredSuggestions.slice(0, length);
    };

    setSearchValue(value) {
        this.searchValue = value;
    }

}

decorate(MentionsStore, {
    searchValue: observable,

    setSearchValue: action,

    suggestions: computed


});