import React, {useContext} from "react";
import {Container, Dropdown, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem} from "reactstrap";
import classnames from 'classnames';
import {Link, NavLink} from "react-router-dom";
import pickATitle from "../utils/PickATitle";
import {observer} from "mobx-react-lite";
import {GlobalStore} from "../stores/GlobalStore";


export const Navigation = observer(() => {
    const {navigation} = useContext(GlobalStore);
    return (
        <Container>
            <Navbar sticky="top">
                <NavbarBrand href="/">{pickATitle()}</NavbarBrand>

                <Nav>
                    <NavItem>
                        <NavLink to={"/study/" + navigation.storyId} className={'nav-link'}
                                 id="study_link">Study</NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={navigation.scribeOpen} toggle={() => navigation.toggleScribe()}
                              id="scribe_link">
                        <DropdownToggle nav caret>
                            Scribe
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link to="/scribe/story" className={'dropdown-item'} id="scribe_story">Compose a
                                Story</Link>
                            <Link to="/scribe/argument" className={'dropdown-item'} id="scribe_argument">Form
                                an
                                Argument</Link>
                            <Link to="/scribe/critique" className={'dropdown-item'} id="scribe_critique">Construct
                                a
                                Critique</Link>
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown nav isOpen={navigation.exploreOpen} toggle={() => navigation.toggleExplore()}>
                        <DropdownToggle nav caret id="explore_link">
                            Explore
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link to="/explore/stories" className={'dropdown-item'} id="explore_stories">Explore
                                Stories</Link>
                            <Link to="/explore/arguments" className={'dropdown-item'}
                                  id="explore_arguments">Explore
                                Arguments</Link>
                            <Link to="/explore/critiques" className={'dropdown-item'}
                                  id="explore_critiques">Explore
                                Critiques</Link>
                            <Link to="/explore/visualize" className={'dropdown-item'}
                                  id="explore_critiques">Visualize</Link>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown nav isOpen={navigation.rememberOpen}
                              toggle={() => navigation.toggleRemember()}>
                        <DropdownToggle nav caret id="remember_link">
                            Remember
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link to="/memory/cookies" className={'dropdown-item'}
                                  id="cookies">Cookies</Link>
                            <Link to="/memory/import_export" className={'dropdown-item'}
                                  id="import_export">Import&Export</Link>
                            <Link to="/memory/default_data" className={'dropdown-item'}
                                  id="default_data">Default data</Link>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                        <NavLink to="/description/"
                                 className={classnames('nav-link')}
                                 id="description_link">Description</NavLink>
                    </NavItem>
                    <NavItem>
                        <small>{process.env.REACT_APP_VERSION + process.env.REACT_APP_STAGE}</small>
                    </NavItem>
                </Nav>
            </Navbar>

        </Container>
    )
});