import React, {useContext} from "react";
import {Alert} from "reactstrap";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const NewArgumentAlert = ({argument, toggle}) => {
        const {exploreFilterStore} = useContext(GlobalStore);
        return (
            <Alert color="success" isOpen={true} toggle={toggle}>
                Argument <Link to="/explore/arguments"
                               onClick={() => exploreFilterStore.toggleArg(argument)}>{argument.id}</Link> was formed.
            </Alert>
        )
    }
;
