import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Badge, Button, ButtonGroup, Card, CardBody, CardHeader, CardText, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {TinyId} from "../other/TinyId";
import {GlobalStore} from "../../stores/GlobalStore";

export const ArgumentView = observer(({argument, onClick}) => {
    const {argumentsStore, stories, alertsStore, formArgumentStore, constructCritiqueStore, exploreFilterStore} = useContext(GlobalStore);

    const selected = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg === argument);
    const iAmTarget = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.targetArgument === argument.id);
    const iAmSourceArg = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.id === argument.targetArgument);
    const iAmSourceStory = (exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === argument.targetStory));
    const iUseAsDefinition = (exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === argument.definition));
    const iAmInCritique = exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique.args.includes(argument.id));
    const iUseAsReason = (exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === argument.reasoning));
    let thereIsObjection = false;
    if (selected) {
        const objection = argumentsStore.allArguments.find(arg => arg.targetArgument === argument.id);
        thereIsObjection = !!objection;
    }


    let part;
    if (argument.selection) {
        part = "Part of "
    } else {
        part = ""
    }

    let id = argument.targetStory ? argument.targetStory : argument.targetArgument;

    let critiqueButton = (
        <Button color={selected ? "info" : "dark"} outline={!selected} onClick={event => {
            event.stopPropagation();
            constructCritiqueStore.addArgument(argument.id);
        }}>Add to Critique</Button>
    );
    if (constructCritiqueStore.args.find(arg => arg === argument.id)) {
        critiqueButton = (
            <Button color={selected ? "danger" : "dark"} outline={!selected} onClick={event => {
                event.stopPropagation();
                constructCritiqueStore.removeArgument(argument.id);
            }}>Remove from Critique</Button>
        );
    }
    return (
        <Card
            outline={selected || iAmTarget || iAmSourceArg || iAmSourceStory || iUseAsReason || iUseAsDefinition || iAmInCritique}
            color={selected ? "primary" : iAmSourceArg ? "danger" : iAmSourceStory || iAmTarget ? "warning" : iUseAsDefinition || iAmInCritique ? "info" : iUseAsReason ? "success" : "light"}
            onClick={onClick}
            id={argument.id}
        >
            <CardHeader>
                {argument.targetStory ? "Argument" : "Objection"}
                {iAmTarget ?
                    <Link to={"/explore/stories"} onClick={event => event.stopPropagation()}><Badge color="warning"
                                                                                                    title={exploreFilterStore.focusedArg.id}
                                                                                                    pill
                                                                                                    className="float-right">T</Badge></Link> : ""}
                {iAmSourceArg ? <Badge color="danger" title={argument.targetArgument} pill
                                       className="float-right">O</Badge> : ""}
                {iAmSourceStory ?
                    <Link to={"/explore/stories"} onClick={event => event.stopPropagation()}><Badge color="warning"
                                                                                                    title={argument.targetStory}
                                                                                                    pill
                                                                                                    className="float-right">A</Badge>
                    </Link> : ""}
                {iUseAsReason ?
                    <Link to={"/explore/stories"} onClick={event => event.stopPropagation()}><Badge color="success"
                                                                                                    title={argument.reasoning}
                                                                                                    pill
                                                                                                    className="float-right">R</Badge>
                    </Link> : ""}
                {iUseAsDefinition ?
                    <Link to={"/explore/stories"} onClick={event => event.stopPropagation()}><Badge color="info"
                                                                                                    title={argument.definition}
                                                                                                    pill
                                                                                                    className="float-right">D</Badge></Link> : ""}
                {iAmInCritique ?
                    <Link to={"/explore/critiques"} onClick={event => event.stopPropagation()}><Badge color="info"
                                                                                                      title={exploreFilterStore.focusedCritique.id}
                                                                                                      pill
                                                                                                      className="float-right">C</Badge></Link> : ""}
            </CardHeader>
            <CardBody>
                <CardTitle> {part}
                    <Link to={"/study/" + id} title={id}
                          className={classnames(iAmSourceArg ? "text-danger" : selected || iAmSourceStory ? "text-warning" : "text-dark")}
                          onClick={event => event.stopPropagation()}
                    >{stories.findTitle(id)}</Link>
                    {' is '}
                    <Link to={"/study/" + argument.definition}
                          className={classnames(iUseAsDefinition || selected ? "text-info" : "text-dark")}
                          title={argument.definition}
                          onClick={event => event.stopPropagation()}
                    >{stories.findTitle(argument.definition)}</Link>.
                </CardTitle>
                <CardText>Because <Link
                    to={'/study/' + argument.reasoning}
                    className={classnames(selected || iUseAsReason ? "text-success" : "text-dark")}
                    title={argument.reasoning}
                    onClick={event => event.stopPropagation()}
                >{stories.findTitle(argument.reasoning)}</Link>.</CardText>
                <ButtonGroup size="sm" className={"flex-wrap"}>
                    <Link to={"/study/" + argument.id}
                          className={classnames('btn', selected ? 'btn-success' : "btn-outline-dark")}
                          onClick={event => event.stopPropagation()}
                    >Focus</Link>
                    <Link className={classnames('btn', selected ? 'btn-primary' : "btn-outline-dark")}
                          to={"/scribe/argument"}
                          onClick={event => {
                              formArgumentStore.objectArgument(argument.id);
                          }}>Object</Link>
                    <Link className={classnames('btn', selected ? 'btn-warning' : "btn-outline-dark")}
                          to={"/scribe/argument"}
                          onClick={event => {
                              formArgumentStore.loadArgument(argument);
                          }}>Get Inspired</Link>
                    {critiqueButton}
                    <Button color={selected ? "danger" : "dark"} outline={!selected} onClick={event => {
                        event.stopPropagation();
                        if (window.confirm('Are you sure you wish to forget this argument?')) {
                            argumentsStore.forgetArgument(argument.id);
                            alertsStore.addWarning("Forgotten argument " + argument.id);
                        }
                    }}>Forget</Button>
                </ButtonGroup>
            </CardBody>
            <TinyId text={argument.id} classes={["card-footer"]}
                    textColor={thereIsObjection ? "text-danger" : iAmTarget ? "text-warning" : "text-muted"}/>
        </Card>
    )
});