import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Badge, Card, CardBody, CardHeader, CardText} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";

export const NonExistingStoryPanel = observer(({storyId, onClick}) => {
    const {exploreFilterStore} = useContext(GlobalStore);

    // const selected = exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory === story);
    const selected = false;
    const iAmTarget = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.targetStory === storyId);
    const iAmDefinition = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.definition === storyId);
    const iAmInCritique = exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique.story === storyId);
    const iAmReason = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.reasoning === storyId);
    return (
        <Card outline={selected || iAmTarget || iAmDefinition || iAmReason || iAmInCritique}
              color={selected ? "info" : iAmTarget ? "warning" : iAmDefinition || iAmInCritique ? "info" : iAmReason ? "success" : "light"}
              onClick={onClick}
              id={storyId}
        >
            <CardHeader>
                Unknown Story
                {iAmTarget ? <Badge color="warning" title={exploreFilterStore.focusedArg.id} pill
                                    className="float-right">T</Badge> : ""}
                {iAmDefinition ? <Badge color="info" title={exploreFilterStore.focusedArg.id} pill
                                        className="float-right">D</Badge> : ""}
                {iAmInCritique ? <Badge color="info" title={exploreFilterStore.focusedCritique.id} pill
                                        className="float-right">C</Badge> : ""}
                {iAmReason ? <Badge color="success" title={exploreFilterStore.focusedArg.id} pill
                                    className="float-right">R</Badge> : ""}
            </CardHeader>
            <CardBody>
                <CardText>{storyId}</CardText>
            </CardBody>
        </Card>
    )
});