import {action, decorate, observable} from "mobx";

export class ArgumentStudyStore {
    argument;

    constructor(stories, argumentsMap, exploreFilterStore, argumentId) {
        this.stories = stories;
        this.argumentsMap = argumentsMap;
        this.exploreFilterStore = exploreFilterStore;
        if (argumentId) {
            this.argument = argumentsMap.get(argumentId);
        }
    }

    toggleArg(arg) {
        this.exploreFilterStore.toggleArg(arg);
    }

    toggleStory(story) {
        this.exploreFilterStore.toggleStory(story);
    }

}

decorate(ArgumentStudyStore, {
    argument: observable,

    toggleArg: action
});