import React, {useContext} from "react";
import {Alert} from "reactstrap";

import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const NewCritiqueAlert = ({critique, toggle}) => {
        const {exploreFilterStore} = useContext(GlobalStore);
        return (
            <Alert color="success" isOpen={true} toggle={toggle}>
                Critique <Link to="/explore/critiques"
                               onClick={() => exploreFilterStore.toggleCritique(critique)}>{critique.id}</Link> was
                constructed.
            </Alert>
        )
    }
;
