let titles = [
    'Face your inner fears', 'Face me or else',
    'You have a nice face',
    'I cannot face it',
    'Face less',
    "Don't fall down on your face",
    'Plain as nose on your face',
    'Your face is like a thunder',
    'A kick in the face',
    'A slap in the face',
    'Take it at face value',
    'Who is in your face',
    'Are you just another pretty face',
    'Look me in the face',
    'Are you staring at my face',
    'It is written all over your face',
    'What is that blank look on your face',
    "Don't take it at face value",
    'What is that blank look on your face',
    'That is gonna blow up in your face',
    'You cannot see hand in front of your face',
    'Come face to face with it',
    'Disappear of the face of the world',
    'Face away',
    'Face facts',
    'Face off',
    'Get out of my face',
    "Don't give me that face",
    'In your face',
    "Don't laugh in my face",
    "Don't lose face",
    'Stop making faces',
    'Do you have a face?',
    'Show your face',
    'Take it off your face',
    'Poker face',
    'You have powder on your face',
    'You are putting a brave face on it',
    'Put a smile on your face',
    'Put on a brave face',
    "Put a smile on someone's face",
    'You are red in your face',
    'It registers on your face',
    'Show your face',
    "Don't slam the door in my face",
    'Stop staring in my face',
    'Two-faced',
    'Wipe of the face of the earth',
    'Wipe the smile out of your face',
    'You would not know if it would hit you in your face',
    'Is my face red?',
    'Why the long face?',
    'Barefaced lie',
    'You are not just a pretty face',
    'Come to save face',
    'Face it',
    'Face on',
    'Face out',
    'Face up',
    'Hide face in shame',
    'Just another pretty face',
    'Let us face the facts'
];
let randomNumber = Math.floor(Math.random() * (titles.length+500));

export default function pickATitle() {
    if (randomNumber>= titles.length){
        return 'Face'
    }
    document.title = titles[randomNumber];
    return titles[randomNumber];
}