import {action, computed, decorate, observable} from "mobx";
import vis from 'vis';

export class ExploreFilterStore {
    okTags = [];
    badTags = ["definition"];
    stories;
    args;
    critiques;

    focusedStory;
    focusedArg;
    focusedCritique;

    constructor(stories, args, critiques) {
        this.stories = stories;
        this.args = args;
        this.critiques = critiques;
    }

    get filteredStories() {

        let stories = Array.from(this.stories.values());
        if (this.okTags.length > 0) {
            stories = stories.filter(story => this.intersection(story.tags, this.okTags).length > 0)
        }
        if (this.badTags.length > 0) {
            stories = stories.filter(story => this.intersection(story.tags, this.badTags).length === 0)
        }
        return stories;
    }


    get networkState() {
        return new NetworkState(this);
    }


    toggleTag(tag) {
        if (this.okTags.includes(tag)) {
            this.badTags.push(tag);
            this.okTags = this.okTags.filter(t => t !== tag);
        } else if (this.badTags.includes(tag)) {
            this.badTags = this.badTags.filter(t => t !== tag);
        } else {
            this.okTags.push(tag);
        }
    }

    intersection(tags, otherTags) {
        return tags.filter(value => otherTags.includes(value));
    }

    toggleStory(story) {
        if (this.focusedStory === story) {
            this.focusedStory = null;
        } else {
            this.focusedStory = story;
            this.focusedArg = null;
            this.focusedCritique = null;
        }
    }

    toggleArg(arg) {
        if (this.focusedArg === arg) {
            this.focusedArg = null;
        } else {
            this.focusedArg = arg;
            this.focusedStory = null;
            this.focusedCritique = null;
        }
    }

    toggleCritique(critique) {
        if (this.focusedCritique === critique) {
            this.focusedCritique = null;
        } else {
            this.focusedCritique = critique;
            this.focusedStory = null;
            this.focusedArg = null;
        }
    }


}

decorate(ExploreFilterStore, {
    okTags: observable,
    badTags: observable,
    stories: observable,
    args: observable,
    critiques: observable,
    focusedStory: observable,
    focusedArg: observable,
    focusedCritique: observable,

    filteredStories: computed,
    networkState: computed,

    toggleTag: action,
    toggleArg: action,
    toggleStory: action,
    toggleCritique: action
});


export class NetworkState {

    constructor(exploreFilterStore) {
        this.critiques = exploreFilterStore.critiques;
        this.args = exploreFilterStore.args;
        this.filteredStories = exploreFilterStore.filteredStories;


        this.focusedStory = exploreFilterStore.focusedStory;
        this.focusedArg = exploreFilterStore.focusedArg;
        this.focusedCritique = exploreFilterStore.focusedCritique;

        this.exploreFilterStore = exploreFilterStore;

    }


    get graph() {
        let nodes = [];
        let edges = [];

        Array.from(this.critiques.values()).forEach(critique => {
            nodes.push({
                id: critique.id,
                group: 'critique',
                color: this.critiqueColor(critique, this.focusedStory, this.focusedArg, this.focusedCritique),
                title: critique.id,
                original: critique
            });
            if (critique.story) {
                edges.push({from: critique.id, to: critique.story, dashes: true})
            }
            critique.args.forEach(arg => edges.push({from: critique.id, to: arg, dashes: true}));
        });
        Array.from(this.args.values()).forEach(arg => {
            nodes.push({
                id: arg.id,
                group: arg.targetStory ? 'arg' : 'objection',
                color: this.argColor(arg, this.focusedStory, this.focusedArg, this.focusedCritique),
                title: arg.id,
                original: arg
            });
            if (arg.targetStory) {
                edges.push({from: arg.targetStory, to: arg.id, arrows: "from"});
            } else {
                edges.push({from: arg.id, to: arg.targetArgument, arrows: "to"});
            }
            edges.push({from: arg.id, to: arg.definition, arrows: "to", dashes: true});
            edges.push({from: arg.id, to: arg.reasoning, arrows: "from"});
        });
        Array.from(this.filteredStories).forEach(story => nodes.push({
            id: story.id,
            color: this.storyColor(story.id, this.focusedStory, this.focusedArg, this.focusedCritique),
            title: story.id,
            group: 'story',
            original: story
        }));

        this.cache = {
            nodes: new vis.DataSet(nodes),
            edges: new vis.DataSet(edges)
        };
        return this.cache
    }

    static successColor() {
        return {
            background: '#ffffff',
            border: '#5cb85c'
        };
    }

    static infoColor() {
        return {
            background: '#ffffff',
            border: '#5bc0de'
        };
    }

    static dangerColor() {
        return {
            background: '#ffffff',
            border: '#d9534f'
        };
    }

    static selectedColor() {
        return {
            background: '#ffffff',
            border: 'blue'
        };
    }

    static warningColor() {
        return {
            background: '#ffffff',
            border: '#f0ad4e'
        };
    }

    static otherColor() {
        return {
            background: '#97C2FC',
            border: '#2B7CE9'
        };
    }

    storyColor(storyId, focusedStory, focusedArg, focusedCritique) {
        if (focusedStory && focusedStory.id === storyId) {
            return NetworkState.selectedColor();
        }
        if (focusedArg && (focusedArg.targetStory === storyId)) {
            return NetworkState.warningColor();
        }
        if (focusedArg && (focusedArg.definition === storyId)) {
            return NetworkState.infoColor();
        }
        if (focusedCritique && (focusedCritique.story === storyId)) {
            return NetworkState.infoColor();
        }
        if (focusedArg && (focusedArg.reasoning === storyId)) {
            return NetworkState.successColor();
        }
        return NetworkState.otherColor();

    }

    argColor(arg, focusedStory, focusedArg, focusedCritique) {
        if (focusedArg && focusedArg.id === arg.id) {
            return NetworkState.selectedColor()
        }
        if (focusedArg && (focusedArg.id === arg.targetArgument)) {
            return NetworkState.dangerColor()
        }
        if ((focusedArg && focusedArg.targetArgument === arg.id) || (focusedStory && (focusedStory.id === arg.targetStory))) {
            return NetworkState.warningColor()
        }
        if ((focusedStory && (focusedStory.id === arg.definition)) || (focusedCritique && (focusedCritique.args.includes(arg.id)))) {
            return NetworkState.infoColor()
        }
        if (focusedStory && (focusedStory.id === arg.reasoning)) {
            return NetworkState.successColor()
        }
        return NetworkState.otherColor();

    }

    critiqueColor(critique, focusedStory, focusedArg, focusedCritique) {
        if (focusedCritique && focusedCritique.id === critique.id) {
            return NetworkState.selectedColor();
        }
        if (focusedArg && (critique.args.includes(focusedArg.id))) {
            return NetworkState.infoColor();
        }
        if (focusedStory && (focusedStory.id === critique.story)) {
            return NetworkState.infoColor();
        }
        return NetworkState.otherColor();

    }

    onSelect(network, event) {
        if (event.nodes.length > 0) {
            const nodeId = event.nodes[0];
            const node = this.cache.nodes.get(nodeId);

            let newStory = null;
            let newArg = null;
            let newCritique = null;

            if (node.group === 'critique') {
                newCritique = node.original;
            } else if (node.group === 'arg' || node.group === 'objection') {
                newArg = node.original;
            } else if (node.group === 'story') {
                newStory = node.original;
            }
            if (this.focusedStory === newStory && this.focusedArg === newArg && this.focusedCritique === newCritique) {
                network.unselectAll();
            }
            const updates = this.updateColors(newStory, newArg, newCritique);
            this.cache.nodes.update(updates);

            if (updates.length > 0) {
                this.focusedStory = newStory;
                this.focusedArg = newArg;
                this.focusedCritique = newCritique;
                if (this.focusedStory) {
                    this.exploreFilterStore.toggleStory(newStory);
                }
                if (this.focusedArg) {
                    this.exploreFilterStore.toggleArg(newArg);
                }
                if (this.focusedCritique) {
                    this.exploreFilterStore.toggleCritique(newCritique);
                }
            }

            network.unselectAll();
        }
    }

    updateColors(newStory, newArg, newCritique) {

        console.debug("Selected " + (newStory ? newStory.id : newArg ? newArg.id : newCritique ? newCritique.id : "nothing..."));

        let updates = [];
        this.cache.nodes.forEach(node => {

            const oldColor = this.color(node, this.focusedStory, this.focusedArg, this.focusedCritique);
            const newColor = this.color(node, newStory, newArg, newCritique);
            if (JSON.stringify(oldColor) !== JSON.stringify(newColor)) {
                updates.push({id: node.id, color: newColor});
            }
        });
        return updates;
    }

    color(node, focusedStory, focusedArg, focusedCritique) {
        if (node.group === 'story') {
            return this.storyColor(node.original.id, focusedStory, focusedArg, focusedCritique);
        }
        if (node.group === 'arg' || node.group === 'objection') {
            return this.argColor(node.original, focusedStory, focusedArg, focusedCritique);
        }
        if (node.group === 'critique') {
            return this.critiqueColor(node.original, focusedStory, focusedArg, focusedCritique);
        }

    }

    toggleStory(newStory) {
        if (this.focusedStory === newStory) {
            return;
        }
        const updates = this.updateColors(newStory, null, null);
        this.focusedStory = newStory;
        if (this.focusedStory) {
            this.focusedArg = null;
            this.focusedCritique = null;
        }
        this.cache.nodes.update(updates);
    }

    toggleArg(newArg) {
        if (this.focusedArg === newArg) {
            return;
        }
        const updates = this.updateColors(null, newArg, null);
        this.focusedArg = newArg;
        if (this.focusedArg) {
            this.focusedStory = null;
            this.focusedCritique = null;
        }
        this.cache.nodes.update(updates);
    }

    toggleCritique(newCritique) {
        if (this.focusedCritique === newCritique) {
            return;
        }
        const updates = this.updateColors(null, null, newCritique);
        this.focusedCritique = newCritique;
        if (this.focusedCritique) {
            this.focusedStory = null;
            this.focusedArg = null;
        }
        this.cache.nodes.update(updates);
    }

}