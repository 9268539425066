import React from "react";
import {Alert} from "reactstrap";

export const StringAlert = ({message, color, toggle}) => {
        return (
            <Alert color={color} isOpen={true} toggle={toggle}>
                {message}
            </Alert>
        )
    }
;
