import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Badge, Button, ButtonGroup, Card, CardBody, CardHeader, CardText, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {TinyId} from "../other/TinyId";
import {GlobalStore} from "../../stores/GlobalStore";

export const CritiqueView = observer(({critique, onClick}) => {
    const {critiquesStore, constructCritiqueStore, exploreFilterStore, stories, memoryStore, alertsStore} = useContext(GlobalStore);
    const selected = exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique === critique);
    const haveArgument = exploreFilterStore.focusedArg && (critique.args.includes(exploreFilterStore.focusedArg.id));
    const haveStory = exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === critique.story);
    const story = stories.stories.get(critique.story);
    return (
        <Card onClick={onClick}
              outline={selected || haveArgument || haveStory}
              color={selected ? "primary" : haveArgument || haveStory ? "info" : "light"}
              id={critique.id}
        >
            <CardHeader>
                Critique
                {haveArgument ?
                    <Link to={"/explore/arguments"} onClick={event => event.stopPropagation()}><Badge color="info"
                                                                                                      title={exploreFilterStore.focusedArg.id}
                                                                                                      pill
                                                                                                      className="float-right">A</Badge></Link> : ""}
                {haveStory ?
                    <Link to={"/explore/stories"} onClick={event => event.stopPropagation()}> <Badge color="info"
                                                                                                     title={exploreFilterStore.focusedStory.id}
                                                                                                     pill
                                                                                                     className="float-right">S</Badge></Link> : ""}
            </CardHeader>
            <CardBody>
                <CardTitle>{critique.title}</CardTitle>
                <CardText>{story ? <Link to={"/study/" + story.id}
                                         className={classnames(selected || haveStory ? "text-info" : "text-dark")}>{story.title}</Link> : ""}</CardText>
                <ButtonGroup size="sm" className={"flex-wrap"}>

                    <Link className={classnames('btn', selected ? 'btn-warning' : "btn-outline-dark")}
                          to={"/scribe/critique"}
                          onClick={event => {
                              constructCritiqueStore.loadCritique(critique.title, critique.story, critique.args);
                          }}>Get Inspired</Link>
                    <Button color="dark" outline={!selected} onClick={event => {
                        event.stopPropagation();
                        memoryStore.copyToClipBoard(critique);
                    }}>Export To Clipboard</Button>
                    <Button color={selected ? "danger" : "dark"} outline={!selected} onClick={event => {
                        event.stopPropagation();
                        if (window.confirm('Are you sure you wish to forget this argument?')) {
                            critiquesStore.forgetCritique(critique.id);
                            alertsStore.addWarning("Forgotten critique " + critique.id);
                        }
                    }}>Forget</Button>
                </ButtonGroup>
            </CardBody>
            <TinyId text={critique.id} classes={["card-footer"]}/>
        </Card>
    )
});