import {action, computed, decorate, observable} from "mobx";
import {default as DraftPublic, EditorState, RichUtils} from "draft-js";
import {MentionsStore} from "./MentionsStore";

export class ComposeStoryStore {
    editorState = EditorState.createEmpty();
    title = "";
    titleProblem;
    tags = [];

    constructor(stories) {
        this.stories = stories;
    }


    get rawState() {
        return JSON.stringify(DraftPublic.convertToRaw(this.editorState.getCurrentContent()));
    }

    get currentSelection() {
        return this.editorState.getSelection();
    }

    get finalStory() {
        return {
            title: this.title,
            content: this.rawState,
            tags: this.tags.map(tag => tag.text)
        }

    }

    get mentionsStore() {
        return new MentionsStore(Array.from(this.stories.values()).map(story => {
            return {
                name: story.title,
                id: story.id
            }
        }));
    }

    deleteTag(i) {
        this.tags = this.tags.filter((tag, index) => index !== i);
    }

    addTag(tag) {
        this.tags = [...this.tags, tag];
    }

    handleDragOfTag(tag, currPos, newPos) {
        const tags = [...this.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.tags = newTags;
    }

    setState(newState) {
        this.editorState = newState;
    }

    handleKeyCommand(command) {
        const newState = RichUtils.handleKeyCommand(this.editorState, command);

        if (newState) {
            this.editorState = newState;
            return 'handled';
        }

        return 'not-handled';
    }

    toggleInlineStyle(style) {
        this.editorState = RichUtils.toggleInlineStyle(this.editorState, style);
    }

    setTitle(newTitle) {
        this.title = newTitle;
    }

    loadStory(story) {
        this.title = story.title;

        const parsed = JSON.parse(story.content);
        const newContentState = DraftPublic.convertFromRaw(parsed);
        this.editorState = EditorState.createWithContent(newContentState);
        this.tags = story.tags.map(tag => ({id: tag, text: tag}));
    }

    clearStory() {
        this.editorState = EditorState.createEmpty();
        this.title = "";
        this.tags = [];
    }

    validate() {
        let result = true;
        this.title = this.title.trim();
        if (this.title.length === 0) {
            result = false;
            this.titleProblem = "Title cannot be empty";
        } else {
            this.titleProblem = null;
        }
        return result;
    }

}

decorate(ComposeStoryStore, {
    editorState: observable,
    title: observable,
    tags: observable,
    titleProblem: observable,

    rawState: computed,
    currentSelection: computed,
    finalStory: computed,
    mentionsStore: computed,

    deleteTag: action,
    handleDragOfTag: action,
    addTag: action,
    setTitle: action,
    loadStory: action,
    setState: action,
    handleKeyCommand: action,
    toggleInlineStyle: action,
    clearStory: action,
    validate: action,

});