import {action, computed, decorate, observable} from "mobx";


export class ConstructCritiqueStore {
    story;
    title = "";
    stories;
    args = [];
    titleProblem;

    constructor(stories) {
        this.stories = stories;
        this.story = stories.autocompleteFilterStore([], ["definition"]);
    }

    get finalCritique() {
        return {
            title: this.title,
            story: this.story.storyId ? this.story.storyId : null,
            args: this.args
        }

    }

    setTitle(newTitle) {
        this.title = newTitle;
    }

    clearCritique() {
        this.title = "";
        this.story = this.stories.autocompleteFilterStore([], ["definition"]);
        this.args = [];
    }

    loadCritique(title, story, args) {
        this.title = title;
        this.story.selectStory(story);
        this.args.replace(args);
    }

    addArgument(arg) {
        this.args.push(arg);
    }

    removeArgument(arg) {
        this.args = this.args.filter(a => a !== arg);
    }

    validate(){
        let result = true;
        this.title = this.title.trim();
        if (this.title.length===0){
            this.titleProblem = "Title cannot be empty";
            result = false;
        } else {
            this.titleProblem=null;
        }
        return result;
    }


}

decorate(ConstructCritiqueStore, {
    story: observable,
    title: observable,
    stories: observable,
    args: observable,
    titleProblem: observable,

    finalCritique: computed,


    setTitle: action,
    clearCritique: action,
    loadCritique: action,
    addArgument: action,
    removeArgument: action,
    validate: action


});