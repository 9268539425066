import React, {useContext} from "react";
import {
    Button,
    ButtonGroup,
    CardColumns,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {AutoCompleteInput} from "./AutoCompleteInput";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";
import {ArgumentView} from "../panels/ArgumentView";
import {NonExistingArgumentPanel} from "../panels/NonExistingArgumentPanel";


export const ConstructCritiqueView = observer(() => {
    const {constructCritiqueStore, critiquesStore, alertsStore, argumentsStore} = useContext(GlobalStore);
    let args = <FormGroup>You don't have any arguments in the critique add some from the <Link
        to="/explore/arguments">explore tab</Link></FormGroup>;
    if (constructCritiqueStore.args.length > 0) {

        args = <FormGroup>
            Add more arguments into your Critique from the <Link
            to="/explore/arguments">explore tab</Link>
            <CardColumns>
                {
                    constructCritiqueStore.args.map((argumentId, id) => {
                        const argument = argumentsStore.argumentsMap.get(argumentId);
                        return argument ? <ArgumentView argument={argument} key={id}/> :
                            <NonExistingArgumentPanel argumentId={argumentId} key={id}/>
                    })
                }
            </CardColumns>
        </FormGroup>
    }
    return (
        <Container id="form_critique" tag="section">
            <Row tag="header">
                <Col tag="h1">Construct a Critique</Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        if (constructCritiqueStore.validate()) {
                            const critique = critiquesStore.createCritique(constructCritiqueStore.finalCritique);
                            alertsStore.newCritique(critique);
                        } else {
                            alertsStore.addWarning("Cannot create a critique...");
                        }
                    }}>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input invalid={!!constructCritiqueStore.titleProblem} type="text" id="title"
                                   value={constructCritiqueStore.title} onChange={event => {
                                constructCritiqueStore.setTitle(event.target.value);
                            }}/>
                            <FormFeedback>{constructCritiqueStore.titleProblem}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <AutoCompleteInput autoCompleteStore={constructCritiqueStore.story} id="story"
                                               label="Supportive Story" optional={"(optional)"}/>
                        </FormGroup>
                        {args}
                        <ButtonGroup>
                            <Button type="submit" color={"primary"}
                                    id="remember_argument_button">Remember</Button>
                            <Button id="clear_argument_button" color="info" onClick={event => {
                                event.preventDefault();
                                constructCritiqueStore.clearCritique();
                            }}>Clear</Button>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>

        </Container>
    )
});