import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Badge, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {GlobalStore} from "../../stores/GlobalStore";
import {TinyId} from "../other/TinyId";

export const StoryView = observer(({story, onClick}) => {
    const {stories, composeStoryStore, exploreFilterStore, alertsStore} = useContext(GlobalStore);
    let tags = "";
    if (story.tags.length > 0) {
        tags =
            <small>Tags: {story.tags.join(" ")}</small>
    }
    return (
        <Card outline={story.panelOutline}
              color={story.panelColor}
              onClick={onClick}
              id={story.id}
        >
            <CardHeader>
                Story
                {story.targeted ?
                    <Link to={"/explore/arguments"} onClick={event => event.stopPropagation()}><Badge color="warning"
                                                                                                      title={exploreFilterStore.focusedArg.id}
                                                                                                      pill
                                                                                                      className="float-right">T</Badge></Link> : ""}
                {story.usedAsDef ?
                    <Link to={"/explore/arguments"} onClick={event => event.stopPropagation()}><Badge color="info"
                                                                                                      title={exploreFilterStore.focusedArg.id}
                                                                                                      pill
                                                                                                      className="float-right">D</Badge></Link> : ""}
                {story.inCritique ?
                    <Link to={"/explore/critiques"} onClick={event => event.stopPropagation()}><Badge color="info"
                                                                                                      title={exploreFilterStore.focusedCritique.id}
                                                                                                      pill
                                                                                                      className="float-right">C</Badge></Link> : ""}
                {story.usedAsReason ?
                    <Link to={"/explore/arguments"} onClick={event => event.stopPropagation()}><Badge color="success"
                                                                                                      title={exploreFilterStore.focusedArg.id}
                                                                                                      pill
                                                                                                      className="float-right">R</Badge></Link> : ""}
            </CardHeader>
            <CardBody>
                <CardTitle
                    className={classnames(story.titleClass)}
                    title={story.id}>{story.title}</CardTitle>
                <ButtonGroup size="sm" className={"flex-wrap"}>

                    <Link to={"/study/" + story.id}
                          className={classnames('btn', story.selected ? 'btn-success' : "btn-outline-dark")}
                          onClick={event => event.stopPropagation()}
                    >Focus</Link>
                    <Link className={classnames('btn', story.selected ? 'btn-warning' : "btn-outline-dark")}
                          to={"/scribe/story"}
                          onClick={event => {
                              composeStoryStore.loadStory(story);
                          }}>Get Inspired</Link>
                    <Button color={story.selected ? "danger" : "dark"} outline={!story.selected} onClick={event => {
                        event.stopPropagation();
                        if (window.confirm('Are you sure you wish to forget this item?')) {
                            stories.forgetStory(story.id);
                            alertsStore.addWarning("Forgotten story " + story.id);
                        }
                    }}>Forget</Button>
                </ButtonGroup>
            </CardBody>
            <CardFooter>
                {tags}
                <TinyId text={story.id} classes={""}/>
            </CardFooter>

        </Card>
    )
});