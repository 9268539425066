import React, {useContext} from 'react'
import classnames from 'classnames'
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {EditorUtils} from "../../utils/EditorUtils";
import {GlobalStore} from "../../stores/GlobalStore";

export const ArgueSelectionButton = observer(({theme, studyStore}) => {
    const {formArgumentStore} = useContext(GlobalStore);
    return (
        <div
            className={classnames(theme.buttonWrapper)}
            onMouseDown={(e) => e.preventDefault()}
        >
            <Link style={buttonStyle} className={classnames({
                [theme.button]: true
            })} to={"/scribe/argument"} onClick={event => {
                formArgumentStore.loadArgument({
                    targetStory: studyStore.story.id,
                    selection: EditorUtils.selection(studyStore.state)
                })
            }}>
                <button style={buttonStyle} className={classnames({
                    [theme.button]: true
                })}>Argue
                </button>
            </Link>
        </div>
    )
});

const buttonStyle = {
    width: 'auto'
};



