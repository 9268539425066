import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {CritiqueView} from "../panels/CritiqueView";
import {StoryView} from "../panels/StoryView";
import {ArgumentView} from "../panels/ArgumentView";
import {CardColumns} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";


export const FocusedEntity = observer(() => {
    const {argumentsStore, exploreFilterStore, critiquesStore} = useContext(GlobalStore);

    let filteredStories = exploreFilterStore.filteredStories.filter(story =>
        (exploreFilterStore.focusedStory && exploreFilterStore.focusedStory === story) ||
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.targetStory === story.id)) ||
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.definition === story.id)) ||
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.reasoning === story.id)) ||
        (exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique.story === story.id))
    ).map((story, id) => (
        <StoryView story={story} key={id}  onClick={() => exploreFilterStore.toggleStory(story)}/>
    ));

    const filteredArgs = argumentsStore.allArguments.filter(arg =>
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg === arg)) ||
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.targetArgument === arg.id)) ||
        (exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.id === arg.targetArgument)) ||
        ((exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === arg.targetStory))) ||
        ((exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === arg.definition))) ||
        (exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique.args.includes(arg.id))) ||
        (exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === arg.reasoning))
    ).map((argument, id) => (
        <ArgumentView argument={argument} key={id}  onClick={() => exploreFilterStore.toggleArg(argument)}/>
    ));

    const critiques = critiquesStore.allCritiques.filter(critique =>
        (exploreFilterStore.focusedCritique && (exploreFilterStore.focusedCritique === critique)) ||
        (exploreFilterStore.focusedArg && (critique.args.includes(exploreFilterStore.focusedArg.id))) ||
        (exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory.id === critique.story))
    ).map((critique, id) => (
        <CritiqueView critique={critique} key={id}  onClick={() => exploreFilterStore.toggleCritique(critique)}/>
    ));

    return (
        <CardColumns>
            {filteredStories}
            {filteredArgs}
            {critiques}
        </CardColumns>
    );
});