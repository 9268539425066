import {observer} from "mobx-react-lite";
import {StoryView} from "../panels/StoryView";
import React, {useContext} from "react";
import {CardColumns, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const StoriesView = observer(() => {
    const {stories, exploreFilterStore} = useContext(GlobalStore);


    const storiesList = exploreFilterStore.filteredStories.map((story, id) => (
        <StoryView story={story} key={id} onClick={() => exploreFilterStore.toggleStory(story)}/>
    ));
    return (
        <React.Fragment>
            <Container tag="section" id="tags">
                <Row tag="header">
                    <Col>
                        <h2>Tags we are aware of</h2>
                    </Col>
                </Row>
                <Row tag="main">
                    <Col>
                        <ListGroup className={classnames("list-group-horizontal","flex-wrap")}>
                            {stories.tags.map((tag, id) => {
                                return (
                                    <ListGroupItem key={id}
                                                   color={exploreFilterStore.okTags.includes(tag) ? "success" : exploreFilterStore.badTags.includes(tag) ? "danger" : "primary"}
                                                   onClick={() => exploreFilterStore.toggleTag(tag)}>{tag}</ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
            <Container tag="section" id="stories">
                <Row tag="header">
                    <Col>
                        <h2>Stories we are aware of</h2>
                    </Col>
                </Row>
                <Row tag="main">
                    <Col>
                        {storiesList.length > 0 ?
                            <CardColumns> {storiesList} </CardColumns> :
                            <React.Fragment>
                                <p>No stories found. Maybe you want to <Link
                                    to={"/scribe/story"}>compose</Link> your
                                    first
                                    one?</p>
                                <p>Or maybe you would like to <Link to={"/memory/default_data"}> load </Link>the
                                    tutorial
                                    data?</p>
                            </React.Fragment>}
                    </Col>
                </Row>
            </Container>


        </React.Fragment>
    );
});