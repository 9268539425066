import {action, computed, decorate, observable} from "mobx";
import {StringAlert} from "../components/alerts/StringAlert";
import {NewStoryAlert} from "../components/alerts/NewStoryAlert";
import {NewArgumentAlert} from "../components/alerts/NewArgumentAlert";
import {NewCritiqueAlert} from "../components/alerts/NewCritiqueAlert";


export class AlertsStore {
    alerts = [];
    index = 0;

    constructor(maxItems = 3) {
        this.maxItems = maxItems;
    }

    get firstFive() {
        return this.alerts.slice(0, 5);
    }

    addSuccess(message) {
        this.addMessage({
            message: message,
            color: "primary",
            key: this.index++,
            Type: StringAlert
        });
    }

    addError(message) {
        this.addMessage({
            message: message,
            color: "danger",
            key: this.index++,
            Type: StringAlert
        });
    }

    addWarning(message) {
        this.addMessage({
            message: message,
            color: "warning",
            key: this.index++,
            Type: StringAlert
        });
    }

    addMessage(message) {
        this.alerts.push(message);
        if (this.alerts.length > this.maxItems) {

            this.alerts = this.alerts.slice(1, this.maxItems + 1);
        }
        if (this.onMessage) {
            this.onMessage(message);
        }
    }

    dismiss(alert) {
        const index = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
    }

    subscribe(onMessage) {
        this.onMessage = onMessage;
    }

    newStory(story) {
        this.addMessage({
            story: story,
            key: this.index++,
            Type: NewStoryAlert
        });
    }

    newArgument(argument) {
        this.addMessage({
            argument: argument,
            key: this.index++,
            Type: NewArgumentAlert
        });
    }

    newCritique(critique) {
        this.addMessage({
            critique: critique,
            key: this.index++,
            Type: NewCritiqueAlert
        });
    }
}

decorate(AlertsStore, {
    alerts: observable,

    addMessage: action,
    dismiss: action,

    firstFive: computed

});