import React from "react";
import {StudyView} from "./study/StudyView";
import {Route, Switch} from "react-router-dom";
import {DescriptionView} from "./DescriptionView";
import {AlertsView} from "./alerts/AlertsView";
import {ComposeStoryView} from "./scribe/ComposeStoryView";
import {FormArgumentView} from "./scribe/FormArgumentView";
import {ConstructCritiqueView} from "./scribe/ConstructCritiqueView";
import {StoriesView} from "./explore/StoriesView";
import {ArgumentsView} from "./explore/ArgumentsView";
import {CritiquesView} from "./explore/CritiquesView";
import {CookiesView} from "./memory/CookiesView";
import {ImportExportView} from "./memory/ImportExportView";
import {DefaultDataView} from "./memory/DefaultDataView";
import VisualizeView from "./explore/VisualizeView";


export const TabsView = () => (
    <React.Fragment>
        <AlertsView/>
        <Switch>
            <Route path="/" exact component={DescriptionView}/>
            <Route path="/study/" exact component={StudyView}/>
            <Route path="/study/:storyId" component={StudyView}/>
            <Route path="/scribe/story" component={ComposeStoryView}/>
            <Route path="/scribe/argument" component={FormArgumentView}/>
            <Route path="/scribe/critique" component={ConstructCritiqueView}/>
            <Route path="/explore/stories" component={StoriesView}/>
            <Route path="/explore/arguments" component={ArgumentsView}/>
            <Route path="/explore/critiques" component={CritiquesView}/>
            <Route path="/explore/visualize" component={VisualizeView}/>
            <Route path="/memory/cookies" component={CookiesView}/>
            <Route path="/memory/import_export" component={ImportExportView}/>
            <Route path="/memory/default_data" component={DefaultDataView}/>
            <Route path="/description/" component={DescriptionView}/>
        </Switch>
    </React.Fragment>

);