import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {CardColumns, Col, Container, Row} from "reactstrap";
import {ArgumentView} from "../panels/ArgumentView";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const ArgumentsView = observer(() => {
    const {argumentsStore, exploreFilterStore} = useContext(GlobalStore);
    const args = argumentsStore.allArguments.map((argument, id) => (
        <ArgumentView argument={argument} key={id} onClick={() => exploreFilterStore.toggleArg(argument)}/>
    ));
    return (
        <Container tag="section" id="arguments">
            <Row tag="header">
                <Col>
                    <h2>Arguments we are aware of</h2>
                </Col>
            </Row>
            <Row tag="main">
                <Col>
                    {args.length > 0 ?
                        <CardColumns> {args} </CardColumns> :
                        <p>No arguments found. Maybe you want to <Link to={"/scribe/argument"}>form</Link> your
                            first
                            one?</p>}
                </Col>
            </Row>
        </Container>
    );
});

