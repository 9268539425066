import {action, computed, decorate, observable} from "mobx";
import {sha256} from "js-sha256";


export class Critique {
    title;
    story;
    args = [];

    constructor(title, story, args = []) {
        this.title = title;
        this.story = story;
        this.args = args;

    }

    get id() {
        return sha256(this.story + this.args.join());
    }
}

export class CritiquesStore {
    critiquesMap = new Map();

    get allCritiques() {
        return Array.from(this.critiquesMap.values());
    }

    createCritique(newCritique) {
        if (!CritiquesStore.validateCritique(newCritique)){
            return null;
        }
        const critique = new Critique(newCritique.title, newCritique.story, newCritique.args);
        this.critiquesMap.set(critique.id, critique);
        return critique;
    }

    forgetCritique(id) {
        this.critiquesMap.delete(id);
    }



    static validateCritique(critique) {
        return !(!critique.title || critique.title.trim() === "");
    }

    clear(){
        this.critiquesMap.clear();
    }


}

decorate(Critique, {
    title: observable,
    story: observable,
    args: observable,

    id: computed

});

decorate(CritiquesStore, {
    critiquesMap: observable,

    allCritiques: computed,

    createCritique: action,
    forgetCritique: action,
    clear: action
});