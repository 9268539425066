import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";

export const ImportExportView = observer(() => {
    const {memoryStore} = useContext(GlobalStore);
    return (
        <Container tag="section" id="import_export">
            <Row tag="header">
                <h2>Download or paste data</h2>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        memoryStore.importData();
                    }}>
                        <FormGroup>
                            <Label>JSON to import</Label>
                            <Input invalid={!!memoryStore.errorFeedback} valid={!!memoryStore.importMessage}
                                   type="textarea"
                                   onChange={event => memoryStore.setTextToImport(event.target.value)}
                                   value={memoryStore.textToImport}/>
                            <FormFeedback>{memoryStore.errorFeedback}</FormFeedback>
                            <FormFeedback valid>{memoryStore.importMessage}</FormFeedback>
                        </FormGroup>
                        <ButtonGroup>
                            <Button disabled={!memoryStore.importMessage} color="info">Import Data</Button>
                            <Button color="success" onClick={event => {
                                event.preventDefault();
                                memoryStore.downloadZip();
                            }}>Download Whole Memory</Button>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
            <Row>
                <h2>Load data from Pastebin</h2>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        memoryStore.loadPastebin();
                    }}>
                        <FormGroup>
                            <Label for="pastebinUrl">Pastebin ID <Badge id="pastebin_tool_tip"
                                                                        onDoubleClick={event => {
                                                                            memoryStore.setPasteBinUrl("D1M0KbTR");
                                                                        }}>?</Badge></Label>
                            <UncontrolledTooltip placement="right" target="pastebin_tool_tip">
                                <p>https://pastebin.com/<strong>D1M0KbTR</strong></p> Note that Pastebin allows to
                                read your pastes from other web pages only when the paste was created by a pro
                                account.
                            </UncontrolledTooltip>
                            <Input id="pastebinUrl" value={memoryStore.pasteBinUrl} onChange={event => {
                                memoryStore.setPasteBinUrl(event.target.value);
                            }}/>
                        </FormGroup>
                        <ButtonGroup>
                            <Button color="info">Load pastebin</Button>

                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
            <Row>
                <h2>Load data from gist.github.com</h2>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        memoryStore.loadGistGithub();
                    }}>
                        <FormGroup>
                            <Label for="gist_github_url">Gist Github location <Badge id="gits_github_tool_tip"
                                                                                     onDoubleClick={event => {
                                                                                         memoryStore.setGistGithubUrl("https://gist.githubusercontent.com/gezero/2c6c8e007f3544608a3d5ef21160dee1/raw/critique.json");
                                                                                     }}>?</Badge></Label>
                            <UncontrolledTooltip placement="right" target="gits_github_tool_tip">
                                <p>Notice the <strong>raw</strong> part of the url. You can get this link from your
                                    gist when you click on the raw button.</p>
                                <p>https://gist.githubusercontent.com/<strong>/gezero/2c6c8e007f3544608a3d5ef21160dee1/raw/critique.json</strong>
                                </p>
                            </UncontrolledTooltip>
                            <Input invalid={memoryStore.gistGithubUrl.length > 0 && !memoryStore.gistUrlContainsRaw}
                                   id="gist_github_url" value={memoryStore.gistGithubUrl} onChange={event => {
                                memoryStore.setGistGithubUrl(event.target.value);
                            }}/>
                            <FormFeedback>{memoryStore.gistGithubUrl.length > 0 && !memoryStore.gistUrlContainsRaw ?
                                <p>The link should contain <strong>raw</strong></p> : ""}</FormFeedback>
                        </FormGroup>
                        <ButtonGroup>
                            <Button color="info">Load gist</Button>

                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
});