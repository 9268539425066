import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Badge, Button, ButtonGroup, Card, CardBody, CardHeader, CardText} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {GlobalStore} from "../../stores/GlobalStore";

export const NonExistingArgumentPanel = observer(({argumentId, onClick}) => {
    const {exploreFilterStore,constructCritiqueStore,formArgumentStore} = useContext(GlobalStore);

    // const selected = exploreFilterStore.focusedStory && (exploreFilterStore.focusedStory === story);
    const selected = false;
    const iAmTarget = exploreFilterStore.focusedArg && (exploreFilterStore.focusedArg.targetArgument === argumentId);
    let critiqueButton = (
        <Button color="dark" outline onClick={event => {
            event.stopPropagation();
            constructCritiqueStore.addArgument(argumentId);
        }}>Add to Critique</Button>
    );
    if (constructCritiqueStore.args.find(arg => arg === argumentId)) {
        critiqueButton = (
            <Button color="dark" outline onClick={event => {
                event.stopPropagation();
                constructCritiqueStore.removeArgument(argumentId);
            }}>Remove from Critique</Button>
        );
    }
    return (
        <Card outline={selected || iAmTarget }
              color={selected ? "info" : iAmTarget ? "warning" : "light"}
              onClick={onClick}
              id={argumentId}
        >
            <CardHeader>
                Unknown Argument
                {iAmTarget ? <Badge color="warning" title={exploreFilterStore.focusedArg.id} pill
                                    className="float-right">T</Badge> : ""}
            </CardHeader>
            <CardBody>
                <CardText>{argumentId}</CardText>
                <ButtonGroup size="sm" className={"flex-wrap"}>

                    <Link className={classnames('btn', 'btn-outline-dark')} to={"/scribe/argument"}
                          onClick={event => {
                              formArgumentStore.objectArgument(argumentId);
                          }}>Object</Link>
                    {critiqueButton}
                </ButtonGroup>
            </CardBody>
        </Card>
    )
});