import React from "react";
import {observer} from "mobx-react";
import {FormFeedback, FormText, Input, Label, ListGroup, ListGroupItem} from "reactstrap";
import classnames from "classnames";


export const AutoCompleteInput = observer(({autoCompleteStore, id, notFound, ...props}) => {
    let suggestions;
    if (autoCompleteStore.filtered.length > 0) {
        suggestions = (
            <ListGroup>
                {autoCompleteStore.filtered.map(story => {
                    return (
                        <ListGroupItem action key={story.id} onMouseDown={event => {
                            autoCompleteStore.selectStory(story.id);
                        }}>{story.title} <small style={idStyle} className="text-muted">{story.id}</small>
                        </ListGroupItem>
                    )
                })}
            </ListGroup>)
    } else {
        suggestions = (
            <div>
                <em>{notFound ? notFound : "No suggestions, you're on your own!"}</em>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Label for={props.id}>{props.label} {props.optional ?
                <small className={classnames("text-muted")}>{props.optional}</small> : ""}</Label>
            <Input {...props} type="text" invalid={!!autoCompleteStore.problem} value={autoCompleteStore.filter}
                   onChange={event => {
                       autoCompleteStore.setFilter(event.target.value);
                       autoCompleteStore.setShowSuggestions(true);
                   }} onFocus={event => {
                autoCompleteStore.setShowSuggestions(true);
            }} onBlur={event => {
                autoCompleteStore.setShowSuggestions(false);
            }}/>
            <FormFeedback>{autoCompleteStore.problem}</FormFeedback>
            {autoCompleteStore.showSuggestions ? suggestions : ""}
            {autoCompleteStore.storyId != null ?
                <FormText>{autoCompleteStore.storyId}.</FormText> : ""}
        </React.Fragment>
    )
});


const idStyle = {
    fontSize: 10
};