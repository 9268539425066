import {action, computed, decorate, observable} from "mobx";
import {sha256} from "js-sha256";
import {AutoCompleteStore} from "./AutoCompleteStore";
import {default as DraftPublic, EditorState} from "draft-js";

export class Story {
    title;
    content;
    tags = [];

    constructor(title, content, tags = [], exploreFilterStore) {
        this.content = content;
        this.title = title;
        this.tags = tags;
        this.exploreFilterStore = exploreFilterStore;
    }

    get id() {
        return sha256(this.content);
    }

    get selected() {
        return this.exploreFilterStore && this.exploreFilterStore.focusedStory && (this.exploreFilterStore.focusedStory === this);
    }

    get targeted() {
        return this.exploreFilterStore && this.exploreFilterStore.focusedArg && (this.exploreFilterStore.focusedArg.targetStory === this.id);
    }

    get usedAsDef() {
        return this.exploreFilterStore && this.exploreFilterStore.focusedArg && (this.exploreFilterStore.focusedArg.definition === this.id)
    }

    get usedAsReason() {
        return this.exploreFilterStore && this.exploreFilterStore.focusedArg && (this.exploreFilterStore.focusedArg.reasoning === this.id)
    }

    get inCritique() {
        return this.exploreFilterStore && this.exploreFilterStore.focusedCritique && (this.exploreFilterStore.focusedCritique.story === this.id)
    }

    get panelOutline() {
        return this.selected || this.targeted || this.usedAsDef || this.usedAsReason || this.inCritique;
    }

    get panelColor() {
        return this.selected ? "info" : this.targeted ? "warning" : this.usedAsDef || this.inCritique ? "info" : this.usedAsReason ? "success" : "light"
    }

    get titleClass() {
        return this.targeted ? "text-warning" : this.usedAsReason ? "text-success" : this.usedAsDef || this.inCritique ? "text-info" : 'text-white';
    }

    setTitle(title) {
        this.title = title;
    }

    setContent(content) {
        this.content = content;
    }

    get valueObject(){
        return {
            title: this.title,
            content: this.content,
            tags: this.tags
        }
    }

    clear() {
        this.setTitle("");
        this.setContent("");
        this.tags = [];
    }
}

export class StoriesStore {
    stories = new Map();
    tags = ['definition'];

    exploreFilterStore = null;

    get allStories() {
        return Array.from(this.stories.values());
    }

    get suggestions() {  //todo: what is this?
        return this.tags.map(tag => ({id: tag, text: tag}));
    }

    static validateStory(story) {
        if ((!story.title || story.title.trim() === "")) {
            return false;
        }

        try {
            const parsed = JSON.parse(story.content);
            EditorState.createWithContent(DraftPublic.convertFromRaw(parsed));
        } catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }

    createStory(newStory) {
        if (!StoriesStore.validateStory(newStory)) {
            return null;
        }
        const story = new Story(newStory.title, newStory.content, newStory.tags, this.exploreFilterStore);
        this.stories.set(story.id, story);

        this.tags = this.tags.concat(newStory.tags.map(tag => tag.toLowerCase()));


        this.tags = this.tags.filter((item, index) => this.tags.indexOf(item) === index);

        return story;

    }

    forgetStory(id) {
        this.stories.delete(id);
    }

    autocompleteFilterStore(goodTags, badTags, onSelection) {
        return new AutoCompleteStore(this.stories, goodTags, badTags, onSelection);
    }

    findTitle(id) {
        let result = this.stories.get(id);
        if (result) {
            return result.title;
        }
        return id.substring(0, 5) + "...";
    }

    clear() {
        this.stories.clear();
        this.tags = ['definition'];
    }

    setExploreFilterStore(exploreFilterStore) {
        this.exploreFilterStore = exploreFilterStore;
    }

}

decorate(Story, {
    content: observable,
    title: observable,

    id: computed,
    selected: computed,
    targeted: computed,
    usedAsDef: computed,
    usedAsReason: computed,
    inCritique: computed,
    panelOutline: computed,
    panelColor: computed,
    titleClass: computed,
    valueObject: computed,

    setTitle: action,
    setContent: action
});


decorate(StoriesStore, {
    stories: observable,
    tags: observable,

    suggestions: computed,
    allStories: computed,

    createStory: action,
    forgetStory: action,
    autocompleteFilterStore: action

});
