import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Button, ButtonGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";

export const DefaultDataView = observer(() => {
    const {memoryStore,alertsStore} = useContext(GlobalStore);
    return (
        <Container tag="section" id="default_data">
            <Row tag="header">
                <Col>
                    <h2>Default Data Import</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup onClick={event => {
                        memoryStore.setPreventTutorial();
                    }}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Input addon type="checkbox" checked={memoryStore.preventTutorial} onChange={event => {
                                    memoryStore.setPreventTutorial(event.target.checked);
                                }}/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="prepend">Prevent tutorial entities from loading during
                            refresh?</InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup>
                        <Button color="light" onClick={event => {
                            if (window.confirm('Clear Stories? (' +
                                memoryStore.numberOfStories + ')')){
                                memoryStore.clearStories();
                                alertsStore.addWarning("Forgotten all stories...");
                            }
                        }}>Clear Stories</Button>
                        <Button color="light" onClick={event => {
                            if (window.confirm('Clear Arguments? ('+
                                memoryStore.numberOfArguments + ')')) {
                                memoryStore.clearArguments();
                                alertsStore.addWarning("Forgotten all arguments...");
                            }
                        }}>Clear Arguments</Button>
                        <Button color="light" onClick={event => {
                            if (window.confirm('Clear Critiques? (' +
                                memoryStore.numberOfCritiques + ')')) {
                                memoryStore.clearCritiques();
                                alertsStore.addWarning("Forgotten all critiques...");
                            }
                        }}>Clear Critiques</Button>
                        <Button color="light" onClick={event => {
                            if (window.confirm('Clear Stories? (' +
                                memoryStore.numberOfStories + 's/' +
                                memoryStore.numberOfArguments + 'a/'+
                                memoryStore.numberOfCritiques + 'c)')) {
                                memoryStore.clearAll();
                                alertsStore.addWarning("Forgotten all entities...");
                            }
                        }}>Clear Everything</Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Container>
    )
});