import React from "react";
import {Container} from "reactstrap";
import ReactMarkdown from "react-markdown";
import readme from './Description.md'
import {action, decorate, observable} from "mobx";
import {observer} from "mobx-react-lite";

class Markdown {
    markdown = "";

    setMarkdown(markdown) {
        this.markdown = markdown;
    }
}

decorate(Markdown, {
    markdown: observable,
    setMarkdown: action
});

const markdown = new Markdown();


export const DescriptionView =observer( () => (
    <Container>
        <ReactMarkdown source={markdown.markdown}/>
    </Container>
));

fetch(readme).then(res => res.text()).then(text => markdown.setMarkdown(text));
