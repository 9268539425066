import React from 'react';
import './App.css';
import {TabsView} from "./components/TabsView";
import {Navigation} from "./components/Navigation";
import {BrowserRouter as Router} from "react-router-dom";
import {GlobalStore,Stores} from "./stores/GlobalStore";


function App() {
    return (
        <GlobalStore.Provider value={new Stores()}>
            <Router>
                <Navigation/>
                <TabsView/>
            </Router>
        </GlobalStore.Provider>
    );
}


export default App;
