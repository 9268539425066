import React from 'react';
import Editor from 'draft-js-plugins-editor';
import {observer} from "mobx-react";
import createInlineToolbarPlugin, {Separator} from 'draft-js-inline-toolbar-plugin';
import {ButtonGroup, ListGroup} from "reactstrap";
import createRichButtonsPlugin from 'draft-js-richbuttons-plugin';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import {
    BlockquoteButton as BQButton,
    BoldButton as BButton,
    CodeBlockButton,
    CodeButton as CButton,
    HeadlineOneButton,
    HeadlineThreeButton,
    HeadlineTwoButton,
    ItalicButton as IButton,
    OrderedListButton,
    UnderlineButton as UButton,
    UnorderedListButton,
} from 'draft-js-buttons';
import editorStyles from './editorStyles.css';
import {EditorUtils} from "../../utils/EditorUtils";

const inlineToolbarPlugin = createInlineToolbarPlugin();
const {InlineToolbar} = inlineToolbarPlugin;
const blockBreakoutPlugin = createBlockBreakoutPlugin();
const richButtonsPlugin = createRichButtonsPlugin();
const mentionsPlugin = EditorUtils.mentionPlugin();

const {
    // inline buttons
    ItalicButton, BoldButton, MonospaceButton, UnderlineButton,
    // block buttons
    ParagraphButton, BlockquoteButton, CodeButton, OLButton, ULButton, H1Button, H2Button, H3Button, H4Button, H5Button, H6Button
} = richButtonsPlugin;

export const StoryEditor = (observer(class StoryEditor extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.editorStore;
        this.mentionsStore = props.editorStore.mentionsStore;

        this.setEditor = (editor) => {
            this.editor = editor;
        };
        this.focusEditor = () => {
            if (this.editor) {
                this.editor.focus();
            }
        };
    }

    render() {

        const {MentionSuggestions} = mentionsPlugin;
        return (
            <div>
                <ButtonGroup size="sm" id="compose_toolbar">
                    <ItalicButton/>
                    <BoldButton/>
                    <MonospaceButton/>
                    <UnderlineButton/>
                    <b> | &nbsp; </b>
                    <ParagraphButton/>
                    <BlockquoteButton/>
                    <CodeButton/>
                    <OLButton/>
                    <ULButton/>
                    <H1Button/>
                    <H2Button/>
                    <H3Button/>
                    <H4Button/>
                    <H5Button/>
                    <H6Button/>
                </ButtonGroup>
                <div id="compose_story_editor" style={styles.editor} className={editorStyles.editor}
                     onClick={this.focusEditor}>

                    <Editor
                        ref={this.setEditor}
                        editorState={this.store.editorState}
                        handleKeyCommand={this.store.handleKeyCommand.bind(this.store)}
                        onChange={(editorState) => this.store.setState(editorState)}
                        plugins={[mentionsPlugin, inlineToolbarPlugin, blockBreakoutPlugin, richButtonsPlugin]}
                    />
                    <MentionSuggestions
                        onSearchChange={({value}) => this.mentionsStore.setSearchValue(value)}
                        suggestions={this.mentionsStore.suggestions}
                        entryComponent={Entry}
                    />
                    <InlineToolbar>
                        {
                            // may be use React.Fragment instead of div to improve perfomance after React 16
                            (externalProps) => (
                                <div>
                                    <BButton {...externalProps} />
                                    <IButton {...externalProps} />
                                    <UButton {...externalProps} />
                                    <CButton {...externalProps} />
                                    <Separator {...externalProps} />
                                    <HeadlineOneButton {...externalProps} />
                                    <HeadlineTwoButton {...externalProps} />
                                    <HeadlineThreeButton {...externalProps} />
                                    <UnorderedListButton {...externalProps} />
                                    <OrderedListButton {...externalProps} />
                                    <BQButton {...externalProps} />
                                    <CodeBlockButton {...externalProps} />
                                </div>
                            )
                        }
                    </InlineToolbar>
                </div>

            </div>
        );
    }
}));


const styles = {
    editor: {
        border: '1px solid gray',
        minHeight: '6em'
    }
};

export const Entry = (props) => {
    const {
        mention,
        theme,
        searchValue, // eslint-disable-line no-unused-vars
        isFocused, // eslint-disable-line no-unused-vars
        ...parentProps
    } = props;

    return (
        <div {...parentProps} key={mention.name} title={mention.id}>
            {mention.name}
        </div>
    );
};
export const Entries = (props) => {
    const {
        mention,
        theme,
        searchValue, // eslint-disable-line no-unused-vars
        isFocused, // eslint-disable-line no-unused-vars
        ...parentProps
    } = props;

    return (
        <ListGroup {...parentProps} key={mention.name}>
            {props.children}
        </ListGroup>
    );
};
