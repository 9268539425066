import {StoriesStore} from "./StoriesStore";
import {NavigationStore} from "./NavigationStore";
import {ArgumentsStore} from "./ArgumentsStore";
import {AlertsStore} from "./AlertsStore";
import {CritiquesStore} from "./CritiquesStore";
import {FormArgumentStore} from "./FormArgumentStore";
import {MemoryStore} from "./MemoryStore";
import {ConstructCritiqueStore} from "./ConstructCritiqueStore";
import {ExploreFilterStore} from "./ExploreFilterStore";
import {ComposeStoryStore} from "./ComposeStoryStore";
import {createContext} from 'react';

export class Stores {
    stories;
    navigation;
    argumentsStore;
    alertsStore;
    critiquesStore;
    formArgumentStore;
    memoryStore;
    constructCritiqueStore;
    exploreFilterStore;
    composeStoryStore;

    constructor() {
        this.stories = new StoriesStore();
        this.navigation = new NavigationStore();
        this.argumentsStore = new ArgumentsStore();
        this.alertsStore = new AlertsStore(3);
        this.critiquesStore = new CritiquesStore();


        this.formArgumentStore = new FormArgumentStore(this.stories);
        this.memoryStore = new MemoryStore(this.stories, this.argumentsStore, this.critiquesStore, this.alertsStore);
        this.constructCritiqueStore = new ConstructCritiqueStore(this.stories);
        this.exploreFilterStore = new ExploreFilterStore(this.stories.stories, this.argumentsStore.argumentsMap, this.critiquesStore.critiquesMap);
        this.composeStoryStore = new ComposeStoryStore(this.stories.stories);

        this.stories.setExploreFilterStore(this.exploreFilterStore);

        this.memoryStore.init();

    }
}


export const GlobalStore = createContext();
