import {action, computed, decorate, observable} from "mobx";
import {sha256} from "js-sha256";


export class Argument {
    targetStory;
    targetArgument;
    definition;
    reasoning;
    selection;

    constructor(targetStory, targetArgument, definition, reasoning, selection) {
        this.targetStory = targetStory;
        this.targetArgument = targetArgument;
        this.definition = definition;
        this.reasoning = reasoning;
        this.selection = selection;
    }

    get id() {
        return sha256(this.targetStory + this.definition + this.reasoning + this.selectionString() + this.targetArgument);
    }

    selectionString() {
        if (this.selection == null) {
            return "";
        }
        return this.selection.startIndex + this.selection.endIndex + this.selection.startOffset + this.selection.endOffset;
    }
}


export class ArgumentsStore {
    argumentsMap = new Map();

    get allArguments() {
        return Array.from(this.argumentsMap.values());
    }

    static validateArg(arg) {
        return arg.definition && arg.reasoning && (arg.targetStory || arg.targetArgument);

    }

    createArgument(finalArgument) {

        if (!ArgumentsStore.validateArg(finalArgument)) {
            return null;
        }

        const argument = new Argument(finalArgument.targetStory, finalArgument.targetArgument, finalArgument.definition, finalArgument.reasoning, finalArgument.selection);
        this.argumentsMap.set(argument.id, argument);

        return argument;
    }

    forgetArgument(id) {
        this.argumentsMap.delete(id);
    }

    clear() {
        this.argumentsMap.clear();
    }

}

decorate(Argument, {
    targetStory: observable,
    argumentType: observable,
    reasoning: observable,
    selection: observable,


    id: computed,

    setTargetStory: action,
    setArgumentType: action,
    setReasoning: action
});

decorate(ArgumentsStore, {
    argumentsMap: observable,

    allArguments: computed,

    createArgument: action,
    forgetArgument: action,
    clear: action
});