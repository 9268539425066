import React from 'react';
import Editor from 'draft-js-plugins-editor';
import {observer} from "mobx-react";
import {Card, CardBody, CardFooter, CardHeader, Container} from "reactstrap";
import editorStyles from '../scribe/editorStyles.css';

import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import {ArgueSelectionButton} from "./ArgueSelectionButton";
import {Link} from "react-router-dom";
import {EditorUtils} from "../../utils/EditorUtils";
import {TinyId} from "../other/TinyId";

const sideToolbarPlugin = createSideToolbarPlugin();
const {SideToolbar} = sideToolbarPlugin;
const mentionPlugin = EditorUtils.mentionPlugin();


export const StoryViewer = observer(class StoryEditor extends React.Component {
    render() {
        let story = <p>Pick a story to study from the <Link to="/explore/stories">explore</Link> tab.</p>;
        if (this.props.studyStore.story) {
            story = (<Card outline={this.props.studyStore.story.panelOutline}
                           color={this.props.studyStore.story.panelColor}
                           onClick={() => this.props.studyStore.toggleStory()}
            >
                <CardHeader className={this.props.studyStore.story.titleClass}>{this.props.studyStore.story.title}</CardHeader>
                <CardBody tag="article" onClick={(event)=>{
                    event.stopPropagation();
                    if (this.editor){
                        this.editor.focus()
                    }
                }} className={editorStyles.editor}>
                    <Editor readOnly={false}
                            ref={(editor)=>this.editor=editor}
                            editorState={this.props.studyStore.state}
                            customStyleMap={styleMap}
                            onChange={(editorState) => this.props.studyStore.setState(editorState)}
                            plugins={[sideToolbarPlugin, mentionPlugin]}
                    />
                    <SideToolbar>
                        {
                            // may be use React.Fragment instead of div to improve perfomance after React 16
                            (externalProps) => (
                                <div>
                                    <ArgueSelectionButton {...externalProps} title="test" studyStore={this.props.studyStore}/>
                                </div>
                            )
                        }</SideToolbar>
                </CardBody>
                <CardFooter>
                    <TinyId text={this.props.studyStore.story.id} classes={""}/>
                </CardFooter>
            </Card>)
        } else if (this.props.studyStore.unknownId) {
            story = <p>We don't know about story {this.props.studyStore.unknownId}, you can pick a known story in the <Link
                to="/explore/stories">explore</Link> tab.</p>
        }
        return (
            <Container id="story">
                <h1>Story</h1>
                {story}
            </Container>
        );
    }
});

const styleMap = {
    'HIGHLIGHT': {
        backgroundColor: '#fd7e14'
    }
};