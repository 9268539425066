import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";

export const CookiesView = observer(() => {
    const {memoryStore} = useContext(GlobalStore);
    return (
        <Container tag="section" id="cookies">
            <Row tag="header">
                <Col>
                    <h2>Local Storage (Cookies)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup onClick={event => {
                        memoryStore.setAutoLoad();
                    }}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Input addon type="checkbox" checked={memoryStore.autoLoad} onChange={event => {
                                    memoryStore.setAutoLoad(event.target.checked);
                                }}/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="prepend">Load from local storage on reload?</InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup onClick={event => {
                        memoryStore.setAutoSave();
                    }}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Input addon type="checkbox" checked={memoryStore.autoSave} onChange={event => {
                                    memoryStore.setAutoSave(event.target.checked);
                                }}/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="prepend">Auto save to local storage after major
                            events?</InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button color="success" onClick={event => {
                                if (window.confirm('Override local storage? (' +
                                    memoryStore.numberOfStories + 's/' +
                                    memoryStore.numberOfArguments + 'a)')) memoryStore.saveMemory();
                            }}>Overwrite storage</Button>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="prepend">

                            <Button color="danger" onClick={event => {
                                memoryStore.loadMemory();
                            }}>Load from storage</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
});