import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Container} from "reactstrap";
import {GlobalStore} from "../../stores/GlobalStore";

export const AlertsView = observer(() => {
    const {alertsStore} = useContext(GlobalStore);
    const alerts = alertsStore.firstFive.map((alert) => {
        const {Type, ...rest} = alert;
        return (
            <Type {...rest} toggle={() => {
                alertsStore.dismiss(alert);
            }}/>
        );
    });
    return (<Container id="alerts">
        {alerts}
    </Container>);

});
