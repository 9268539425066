import {inject} from "mobx-react";
import {observe} from "mobx";
import React from "react";
import {Col, Container, Row} from "reactstrap";
import './visReactStyles.css';
import vis from 'vis';

import {FocusedEntity} from "./FocusedEntity";
import {GlobalStore} from "../../stores/GlobalStore";


const options = {
    interaction: {
        selectable: true,
        zoomView: true
    },
    manipulation: {
        enabled: false
    },
    groups: {
        story: {
            label: 'S'
        },
        critique: {
            label: 'C'
        },
        arg: {
            label: 'A'
        },
        objection: {
            label: 'O'
        }
    },
    nodes: {
        shape: 'ellipse',
        size: 30,
        widthConstraint: {minimum: 40},
        font: {
            size: 20,
        },
        borderWidth: 2
    },
    edges: {
        color: {
            color: '#848484'
        },
        width: 2

    }
};


export const VisualizeView = inject("exploreFilterStore",)(class VisualizeView extends React.Component {

    constructor(props) {
        super(props);
        this.graphDiv = React.createRef();
        this.exploreFilterStore = props.exploreFilterStore;
        this.networkState = this.exploreFilterStore.networkState

    }

    newNetwork(){
        const network = new vis.Network(this.graphDiv.current, this.networkState.graph, options);
        network.on("select", (event) => {
            this.networkState.onSelect(this.network, event)
        });
        network.on("dragEnd", (event) => {
            this.networkState.onSelect(this.network, event)
        });
        return network;
    }

    componentDidMount() {
        this.network = this.newNetwork();
        this.disposeStory = observe(this.exploreFilterStore, "focusedStory", ({newValue}) => {
            this.networkState.toggleStory(newValue)
        });
        this.disposeArg = observe(this.exploreFilterStore, "focusedArg", ({newValue}) => {
            this.networkState.toggleArg(newValue)
        });
        this.disposeCritique = observe(this.exploreFilterStore, "focusedCritique", ({newValue}) => {
            this.networkState.toggleCritique(newValue)
        });
        this.disposeStories = observe(this.exploreFilterStore.stories, () => {
            this.networkState = this.exploreFilterStore.networkState;
            this.network = this.newNetwork();
        });
        this.disposeArgs = observe(this.exploreFilterStore.args, () => {
            this.networkState = this.exploreFilterStore.networkState;
            this.network = this.newNetwork();
        });
        this.disposeCritiques = observe(this.exploreFilterStore.critiques, () => {
            this.networkState = this.exploreFilterStore.networkState;
            this.network = this.newNetwork();
        });
    }

    componentWillUnmount() {
        this.disposeStory();
        this.disposeArg();
        this.disposeCritique();
        this.disposeStories();
        this.disposeArgs();
        this.disposeCritiques();
    }

    render() {
        return (
            <Container>
                <Row>
                    <div ref={this.graphDiv} className="jumbotron vis-react col"/>
                </Row>
                <Row>
                    <Col>
                        <FocusedEntity/>
                    </Col>
                </Row>
            </Container>
        );
    }

});

export default React.forwardRef((props, ref) => (
    <GlobalStore.Consumer>
        {({exploreFilterStore}) => <VisualizeView {...props} exploreFilterStore={exploreFilterStore} ref={ref}/>}
    </GlobalStore.Consumer>
));