import {EditorState, SelectionState} from "draft-js";
import createMentionPlugin from "draft-js-mention-plugin";
import {Link} from "react-router-dom";
import React from "react";


export class EditorUtils {

    static applySelection(editorState, selection) {
        const startKey = this.findKey(editorState, selection.startIndex);
        const endKey = this.findKey(editorState, selection.endIndex);
        const selectionState = new SelectionState({
            anchorKey: startKey,
            anchorOffset: selection.startOffset,
            focusKey: endKey,
            focusOffset: selection.endOffset
        });
        return EditorState.acceptSelection(editorState, selectionState);
    }


    static findKey(editorState, index) {
        if (index < 0) {
            return null;
        }
        const keys = editorState.getCurrentContent().getBlockMap().keys();
        let i = 0;
        for (const k of keys) {
            if (i === index) {
                return k;
            }
            i++;
        }
        return null;
    }


    static indexOf(editorState, key) {
        const keys = editorState.getCurrentContent().getBlockMap().keys();
        let i = 0;
        for (const k of keys) {
            if (k === key) {
                return i;
            }
            i++;
        }
        return -1;
    }

    static selection(editorState) {
        const selection = editorState.getSelection();
        return {
            startIndex: EditorUtils.indexOf(editorState, selection.getStartKey()),
            startOffset: selection.getStartOffset(),
            endIndex: EditorUtils.indexOf(editorState, selection.getEndKey()),
            endOffset: selection.getEndOffset()
        }
    }

    static mentionPlugin(){
        return createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true,
            mentionComponent: (mentionProps) => (
                <Link className={mentionProps.className} to={"/study/" + mentionProps.mention.id}>
                    {mentionProps.children}
                </Link>
            )

        })
    }

}