import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Button, ButtonGroup, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {StoryEditor} from "./StoryEditor";
import {WithContext as ReactTags} from 'react-tag-input';
import './reactTags.css';
import {GlobalStore} from "../../stores/GlobalStore";


const KeyCodes = {
    comma: 188,
    enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const ComposeStoryView = observer(() => {
    const {composeStoryStore, stories, alertsStore} = useContext(GlobalStore);
    return (
        <Container id="compose_story" tag="section">
            <Row tag="header">
                <h1>Compose a Story</h1>
            </Row>
            <Row tag="main">
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        if (composeStoryStore.validate()) {
                            const story = stories.createStory(composeStoryStore.finalStory);
                            alertsStore.newStory(story);
                        } else {
                            alertsStore.addWarning("Cannot create a story...");
                        }
                    }}>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input id="title" invalid={!!composeStoryStore.titleProblem} type="text"
                                   value={composeStoryStore.title}
                                   onChange={event => composeStoryStore.setTitle(event.target.value)}/>
                            <FormFeedback>{composeStoryStore.titleProblem}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags" sm={2}>Tags</Label>
                            <ReactTags tags={composeStoryStore.tags}
                                       suggestions={stories.suggestions}
                                       handleDelete={(i) => composeStoryStore.deleteTag(i)}
                                       handleAddition={(tag) => composeStoryStore.addTag(tag)}
                                       handleDrag={(tag, currPos, newPos) => composeStoryStore.handleDragOfTag(tag, currPos, newPos)}
                                       delimiters={delimiters}
                                       id="tags"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Content</Label>
                            <StoryEditor editorStore={composeStoryStore}/>
                        </FormGroup>
                        <ButtonGroup>
                            <Button type="submit" id="remember_story_button" color="primary">Remember</Button>
                            <Button id="clear_story_button" color="info" onClick={event => {
                                event.preventDefault();
                                composeStoryStore.clearStory();
                            }}>Clear</Button>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
});