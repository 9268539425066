import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, FormText} from "reactstrap";
import Editor from "draft-js-plugins-editor";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import {ConfirmSelectionButton} from "./ConfirmSelectionButton";
import {GlobalStore} from "../../stores/GlobalStore";

const inlineToolbarPlugin = createInlineToolbarPlugin();
const {InlineToolbar} = inlineToolbarPlugin;

export const SelectFragmentView = observer(() => {
    const {formArgumentStore} = useContext(GlobalStore);

    if (formArgumentStore.targetStory.storyId == null) {
        return (<React.Fragment/>);
    }

    if (formArgumentStore.selectedFragment == null) {
        return (
            <FormText onClick={event => {
                formArgumentStore.prepareFragment()
            }}>You are discussing the whole story,
                do you want
                to quote just a fragment of it?</FormText>)
    }
    let editor = "";
    if (formArgumentStore.selectedFragment.expand) {
        editor = (
            <Card>
                <CardHeader>{formArgumentStore.selectedFragment.story.title}</CardHeader>
                <CardBody>

                    <Editor readOnly={false}
                            editorState={formArgumentStore.selectedFragment.editorState}
                            onChange={(editorState) => formArgumentStore.selectedFragment.setState(editorState)}
                            plugins={[inlineToolbarPlugin]}
                    />
                    <InlineToolbar>{
                        // may be use React.Fragment instead of div to improve perfomance after React 16
                        (externalProps) => (
                            <div>
                                <ConfirmSelectionButton {...externalProps} confirmSelection={event => {
                                    event.preventDefault();
                                    formArgumentStore.selectedFragment.collapse();
                                }}/>
                            </div>
                        )
                    }</InlineToolbar>
                </CardBody>
                <CardFooter><Button outline onClick={event => {
                    event.preventDefault();
                    formArgumentStore.selectedFragment.collapse();
                }}>Argue current selection</Button></CardFooter>
            </Card>
        );

    }

    return (
        <div id="fragment_select_editor">
            <FormText onClick={event => {
                if (window.confirm('Do you want to remove selected fragment?')) formArgumentStore.clearFragment()
            }}>You are discussing fragment{" "}
                <strong>{formArgumentStore.selectedFragment.startIndex}/{formArgumentStore.selectedFragment.currentSelection.getStartOffset()}{" - "}
                    {formArgumentStore.selectedFragment.endIndex}/{formArgumentStore.selectedFragment.currentSelection.getEndOffset()}</strong>{". "}
                <em>Do you want to discuss whole story instead?</em>
            </FormText>
            {editor}
        </div>

    )
});
