import React from "react";
import {Col, Container, Row} from "reactstrap";
import {StoryViewer} from "./StoryViewer";
import {ArgumentView} from "../panels/ArgumentView";
import {observer} from "mobx-react-lite";

export const StoryStudyView = observer(({studyStore}) => {
    let reasoning = studyStore.reasonings.map((arg, index) => (
        <ArgumentView argument={arg} key={arg.id} onClick={() => studyStore.toggleArg(arg)}/>
    ));
    const targets = studyStore.targets.map((arg, index) => (
        <ArgumentView argument={arg} key={arg.id} onClick={() => studyStore.toggleArg(arg)}/>
    ));

    let definitions = studyStore.definitions.map((arg, index) => (
        <ArgumentView argument={arg} key={arg.id} onClick={() => studyStore.toggleArg(arg)}/>
    ));
    let right = null;
    if (targets.length > 0 || definitions.length > 0) {
        right = (
            <Col id="target" tag="aside" sm={reasoning.length > 0 ? 3 : 4}>
                {targets.length > 0 ? <h3>Target by</h3> : ""}
                {targets}

                {definitions.length > 0 ? <h3>Used by</h3> : ""}
                {definitions}
            </Col>
        )
    }

    let left = null;
    if (reasoning.length > 0) {
        left = <Col tag="aside" id="reasoning" sm={right ? 3 : 4}>
            <h3>Reasoning in</h3>
            {reasoning}
        </Col>
    }

    return (
        <Container>
            <Row tag="section">
                {left}
                <Col tag="main" sm={left ? right ? 6 : 8 : right ? 8 : 12}>
                    <StoryViewer studyStore={studyStore}/>
                </Col>
                {right}
            </Row>
        </Container>
    );
});

