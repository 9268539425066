import {action, decorate, observable} from "mobx";

export class NavigationStore {
    scribeOpen = false;
    exploreOpen = false;
    rememberOpen = false;

    storyId = "";

    toggleScribe() {
        this.scribeOpen = !this.scribeOpen;
        this.exploreOpen = false;
        this.rememberOpen = false;
    }

    toggleExplore() {
        this.scribeOpen = false;
        this.exploreOpen = !this.exploreOpen;
        this.rememberOpen = false;
    }

    toggleRemember() {
        this.scribeOpen = false;
        this.exploreOpen = false;
        this.rememberOpen = !this.rememberOpen;
    }

    setStoryId(storyId) {
        this.storyId = storyId;
    }

}

decorate(NavigationStore, {
    scribeOpen: observable,
    exploreOpen: observable,
    rememberOpen: observable,
    storyId: observable,


    toggleScribe: action,
    toggleExplore: action,
    toggleRemember: action,
    setStoryId: action
});