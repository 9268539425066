import React from "react";
import classnames from "classnames";
import "./tinyId.css"

export const TinyId = ({text, classes, textColor}) => {
    return (
        <div className={classnames(...classes, "tinyId-container", textColor?textColor:"text-muted")} title={text}>
            <small className={classnames(textColor?textColor:"text-muted", "tinyId-text")}>{text}</small>
        </div>
    );
};

