import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {AutoCompleteInput} from "./AutoCompleteInput";
import {SelectFragmentView} from "./SelectFragmentView";
import {GlobalStore} from "../../stores/GlobalStore";

export const FormArgumentView = observer(() => {
    const {formArgumentStore, argumentsStore, alertsStore} = useContext(GlobalStore);
    let argumentTarget = <div>This is should not happen...</div>;
    if (formArgumentStore.targetStory) {
        argumentTarget = (
            <React.Fragment>
                <AutoCompleteInput autoCompleteStore={formArgumentStore.targetStory} id="story"
                                   label="Target Story"/>
                <SelectFragmentView/>
            </React.Fragment>
        );
    }
    if (formArgumentStore.targetArgument) {
        argumentTarget = (
            <React.Fragment>
                <Label for="target_argument">Target Argument</Label>
                <Input type="text" value={formArgumentStore.targetArgument} disabled/>
            </React.Fragment>
        );
    }
    return (
        <Container id="construct_argument" tag="section">
            <Row tag="header">
                <Col tag="h1">Form an Argument {formArgumentStore.targetArgument ? (
                    <small className="text-muted">(Objection)</small>) : ""}</Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={event => {
                        event.preventDefault();
                        if (formArgumentStore.validate()) {
                            const argument = argumentsStore.createArgument(formArgumentStore.finalArgument);
                            alertsStore.newArgument(argument);
                        } else {
                            alertsStore.addWarning("Cannot create an argument...");
                        }
                    }}>
                        <FormGroup>
                            {argumentTarget}
                        </FormGroup>
                        <FormGroup>
                            <AutoCompleteInput autoCompleteStore={formArgumentStore.definition}
                                               id="argumentType"
                                               label="Argument Type" optional="(definition)"
                                               notFound="Found nothing... Does your story have the definition tag?"/>
                        </FormGroup>
                        <FormGroup>
                            <AutoCompleteInput id="reasoning" type="text"
                                               autoCompleteStore={formArgumentStore.reasoning}
                                               label="Reasoning"/>
                        </FormGroup>
                        <ButtonGroup>
                            <Button type="submit" id="remember_argument_button"
                                    color="primary">Remember</Button>
                            <Button color="info" id="clear_argument_button" onClick={event => {
                                event.preventDefault();
                                formArgumentStore.clearArgument();
                            }}>Clear</Button>
                            <Button color="info" id="clear_argument_button" onClick={event => {
                                event.preventDefault();
                                formArgumentStore.clearTarget();
                            }}>Clear Target</Button>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
});