import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoryStudyStore} from "../../stores/StoryStudyStore";
import {GlobalStore} from "../../stores/GlobalStore";
import {ArgumentStudyStore} from "../../stores/ArgumentStudyStore";
import {StoryStudyView} from "./StoryStudyView";
import {ArgumentStudyView} from "./ArgumentStudyView";

export const StudyView = observer(({match}) => {
    const {stories, argumentsStore, exploreFilterStore, navigation} = useContext(GlobalStore);
    const id = match.params.storyId;
    navigation.setStoryId(id);

    if (argumentsStore.argumentsMap.get(id)) {
        const argumentsStudyStore = new ArgumentStudyStore(stories.stories, argumentsStore.argumentsMap, exploreFilterStore, id);

        return <ArgumentStudyView argumentsStudyStore={argumentsStudyStore}/>;
    }

    const studyStore = new StoryStudyStore(stories.stories, argumentsStore.argumentsMap, exploreFilterStore, id);

    return <StoryStudyView studyStore={studyStore}/>;

});