import {action, computed, decorate, observable} from "mobx";


export class FormArgumentStore {
    targetStory;
    selectedFragment;
    definition;
    reasoning;
    stories;
    targetArgument;

    constructor(stories) {
        this.stories = stories;
        this.targetStory = stories.autocompleteFilterStore([], ["definition"], this.clearFragment.bind(this));
        this.definition = stories.autocompleteFilterStore(["definition"]);
        this.reasoning = stories.autocompleteFilterStore([], ["definition"]);
    }

    get selection() {
        if (this.selectedFragment == null) {
            return null;
        }
        return {
            startIndex: this.selectedFragment.startIndex,
            startOffset: this.selectedFragment.currentSelection.getStartOffset(),
            endIndex: this.selectedFragment.endIndex,
            endOffset: this.selectedFragment.currentSelection.getEndOffset()
        };
    }

    get finalArgument() {
        return {
            targetStory: this.targetStory && this.targetStory.storyId ? this.targetStory.storyId : null,
            targetArgument: this.targetArgument,
            definition: this.definition.storyId ? this.definition.storyId : null,
            reasoning: this.reasoning.storyId ? this.reasoning.storyId : null,
            selection: this.selection
        }

    }

    loadArgument(argument) {
        if (argument.targetStory) {
            this.targetStory = this.stories.autocompleteFilterStore([], ["definition"], this.clearFragment.bind(this));
            this.targetStory.selectStory(argument.targetStory);
        } else if (argument.targetArgument) {
            this.targetArgument = argument.targetArgument;
        }
        if (argument.definition) {
            this.definition.selectStory(argument.definition);
        }
        if (argument.reasoning) {
            this.reasoning.selectStory(argument.reasoning);
        }
        if (argument.targetStory && (argument.selection)) {
            this.selectedFragment = this.targetStory.fragmentForSelection(argument.selection);
        } else {
            this.selectedFragment = null;
        }
    }

    prepareFragment() {
        this.selectedFragment = this.targetStory.fragmentForSelection();
    }

    clearFragment() {
        this.selectedFragment = null;
    }

    clearArgument() {
        this.clearTarget();
        this.definition = this.stories.autocompleteFilterStore("definitions");
        this.reasoning = this.stories.autocompleteFilterStore([], ["definition"]);

    }

    clearTarget() {
        this.clearFragment();
        this.targetArgument = null;
        this.targetStory = this.stories.autocompleteFilterStore([], ["definition"], this.clearFragment.bind(this));

    }

    objectArgument(arg) {
        this.targetStory = null;
        this.selectedFragment = null;
        this.targetArgument = arg;
    }


    validate() {
        let result = true;
        if (!this.targetArgument && !this.targetStory.storyId) {
            result = false;
            this.targetStory.setProblem("You have to pick target for your argument.");
        } else {
            if (this.targetStory) {
                this.targetStory.setProblem(null);
            }
        }

        if (!this.definition.storyId) {
            result = false;
            this.definition.setProblem("You have to pick a definition for your argument.")
        } else {
            this.definition.setProblem(null)
        }

        if (!this.reasoning.storyId) {
            result = false;
            this.reasoning.setProblem("You have to pick a reasoning for your argument.")
        } else {
            this.reasoning.setProblem(null)
        }

        return result;
    }
}

decorate(FormArgumentStore, {
    targetStory: observable,
    targetArgument: observable,
    definition: observable,
    reasoning: observable,
    selectedFragment: observable,

    prepareFragment: action,
    clearFragment: action,
    clearArgument: action,
    loadArgument: action,
    objectArgument: action,
    clearTarget: action,
    validate: action,

    selection: computed,
    finalArgument: computed
});