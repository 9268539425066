import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {CardColumns, Col, Container, Row} from "reactstrap";
import {CritiqueView} from "../panels/CritiqueView";
import {Link} from "react-router-dom";
import {GlobalStore} from "../../stores/GlobalStore";

export const CritiquesView = observer(() => {
    const {critiquesStore, exploreFilterStore} = useContext(GlobalStore);
    const critiques = critiquesStore.allCritiques.map((critique, id) => (
        <CritiqueView critique={critique} key={id} onClick={() => exploreFilterStore.toggleCritique(critique)}/>
    ));
    return (
        <Container tag="section" id="critiques">
            <Row tag="header">
                <Col>
                    <h2>Critiques we are aware of</h2>
                </Col>
            </Row>
            <Row tag="main">
                <Col>
                    {critiques.length > 0 ?
                        <CardColumns> {critiques} </CardColumns> :
                        <p>No critiques found. Maybe you want to <Link to={"/scribe/critique"}>construct</Link> your
                            first
                            one?</p>}
                </Col>
            </Row>
        </Container>
    );
});