import React from 'react'
import classnames from 'classnames'

export const ConfirmSelectionButton = ({theme, confirmSelection}) => {
    return (
        <div
            className={classnames(theme.buttonWrapper)}
            onMouseDown={(e) => e.preventDefault()}
        >
            <button style={buttonStyle} className={classnames({
                [theme.button]: true
            })} onClick={confirmSelection}>Confirm fragment
            </button>
        </div>
    )
};

const buttonStyle = {
    width: 'auto'
};



