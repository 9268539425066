import {action, computed, decorate, observable} from "mobx";
import {default as DraftPublic, EditorState, RichUtils} from "draft-js";
import {EditorUtils} from "../utils/EditorUtils";

export class StoryStudyStore {
    story;
    state;
    unknownId;

    constructor(stories, argumentsMap, exploreFilterStore, storyId) {
        this.stories = stories;
        this.argumentsMap = argumentsMap;
        this.exploreFilterStore = exploreFilterStore;
        if (storyId) {
            const story = stories.get(storyId);
            if (story) {
                this.loadStory(story);
            } else {
                this.setUnknownId(storyId);
            }
        }
    }

    get id() {
        return this.story ? this.story.id : this.unknownId;
    }

    get reasonings() {
        if (this.id) {
            return Array.from(this.argumentsMap.values()).filter(arg => arg.reasoning === this.id)
        }
        return [];
    }

    get targets() {
        if (this.id) {
            return Array.from(this.argumentsMap.values()).filter(arg => arg.targetStory === this.id)
        }
        return [];
    }

    get definitions() {
        if (this.id) {
            return Array.from(this.argumentsMap.values()).filter(arg => arg.definition === this.id)
        }
        return [];
    }

    loadStory(story) {
        this.story = story;
        this.resetState();
    }

    setUnknownId(id) {
        this.story = null;
        this.resetState();
        this.unknownId = id;
    }

    clearStory() {
        this.story = null;
        this.resetState();
    }

    resetState() {
        if (!this.story) {
            this.state = null;
            return;
        }
        this.unknownId = null;
        const parsed = JSON.parse(this.story.content);
        this.state = EditorState.createWithContent(DraftPublic.convertFromRaw(parsed));

        if (this.exploreFilterStore.focusedArg && this.exploreFilterStore.focusedArg.targetStory === this.story.id && this.exploreFilterStore.focusedArg.selection) {
            this.state = EditorUtils.applySelection(this.state, this.exploreFilterStore.focusedArg.selection);
            this.state = RichUtils.toggleInlineStyle(this.state, 'HIGHLIGHT');
            this.state = EditorState.moveSelectionToEnd(this.state);
        }

    }

    setState(newState) {
        if (!this.state) {
            this.state = newState;
            return;
        }
        const currentContentState = this.state.getCurrentContent();
        const newContentState = newState.getCurrentContent();

        if (currentContentState !== newContentState) {
            console.log("don't change the content please...");
            this.resetState();
        } else {
            this.state = newState;
        }
    }

    toggleArg(arg) {
        this.exploreFilterStore.toggleArg(arg);
        this.resetState();
    }

    toggleStory() {
        this.exploreFilterStore.toggleStory(this.story);
        this.resetState();
    }

}

decorate(StoryStudyStore, {
    story: observable,
    arg: observable,
    state: observable,
    unknownId: observable,

    loadStory: action,
    setUnknownId: action,
    clearStory: action,
    resetState: action,
    setState: action,
    toggleArg: action,

    reasonings: computed,
    definitions: computed,
    targets: computed,
    id: computed
});